import React, { ReactNode } from "react";

import { Info as InfoIcon } from "@mui/icons-material";
import { Alert, AlertColor, Link, styled } from "@mui/material";
import { doc, updateDoc } from "firebase/firestore";

import Typography from "@components/Typography";

import useToast from "@hooks/useToast";

import Intl from "@interfaces/database/Intl";
import JobID from "@interfaces/database/JobID";

import { FIRESTORE_COLLECTIONS, JOB_POSTING_STATUS } from "@utils/config";
import { db } from "@utils/firebase";
import Timestamp from "@utils/Timestamp";
import translate, { intl as globalIntl } from "@utils/translate";

const StyledAlert = styled(Alert)({
  boxShadow: "none !important",
  wordBreak: "break-word"
});

interface JobStatusAlert {
  status: typeof JOB_POSTING_STATUS[keyof typeof JOB_POSTING_STATUS];
  jobId?: JobID;
  intl?: Intl;
  handleClickHere?: () => void;
}

const getAlertDataByJobStatus = (
  status: typeof JOB_POSTING_STATUS[keyof typeof JOB_POSTING_STATUS],
  jobId: JobID,
  intl: Intl
): { severity: AlertColor; message: ReactNode } => {
  const currentLocale = intl.getInitOptions().currentLocale; // this is locale of local component so it will changed based on intl props
  const currentLocalShort = translate.getLocaleShort(currentLocale);
  const toast = useToast();
  switch (status) {
    case JOB_POSTING_STATUS.HIDDEN: {
      const handleClickHere = async (): Promise<void> => {
        try {
          const jobDocumentReference = doc(
            db,
            `${FIRESTORE_COLLECTIONS.JOBS}/${jobId}`
          );
          await updateDoc(jobDocumentReference, {
            status: JOB_POSTING_STATUS.SUBMITTED_FOR_AUTO_REVIEW,
            updated_at: Timestamp.now()
          });
          toast.kampai(
            intl.get("t_job_status_hidden_to_publish_success"),
            "success"
          );
        } catch (error) {
          toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
        }
      };
      return {
        severity: "warning",
        message: (
          <Typography variant="body1">
            {intl.get("t_job_status_hidden_alert_msg_first_part")}
            &nbsp;
            <Link
              href="#"
              onClick={() => {
                handleClickHere();
              }}
              color="inherit">
              {intl.get("t_general_click_here")}
            </Link>
            &nbsp;
            {intl.get("t_job_status_hidden_alert_msg_second_part")}
          </Typography>
        )
      };
    }
    case JOB_POSTING_STATUS.DRAFT:
      return {
        severity: "info",
        message: (
          <Typography variant="body1">
            {intl.get("t_job_status_draft_alert_msg_first_part")}
            &nbsp;
            <Link
              href={`/${translate.getCurrentLocale()}/employers/jobs/${jobId}/${currentLocalShort}/company-information`}
              target="_blank"
              color="inherit"
              rel="noopener noreferrer">
              {intl.get("t_general_click_here")}
            </Link>
            &nbsp;
            {intl.get("t_job_status_draft_alert_msg_second_part")}
          </Typography>
        )
      };
    case JOB_POSTING_STATUS.OK_AUTO_REVIEWED:
    case JOB_POSTING_STATUS.OK_MANUAL_REVIEWED:
      return {
        severity: "success",
        message: (
          <Typography variant="body1">
            {intl.get("t_job_status_ok_alert_msg_first_part")}
            &nbsp;
            <Link
              href={`/${currentLocale}/jobs/${jobId}`}
              target="_blank"
              color="inherit"
              rel="noopener noreferrer">
              {intl.get("t_general_click_here")}
            </Link>
            &nbsp;
            {intl.get("t_job_status_ok_alert_msg_second_part")}
          </Typography>
        )
      };
    case JOB_POSTING_STATUS.FLAGGED:
      return {
        severity: "error",
        message: (
          <Typography variant="body1">
            {intl.get("t_job_status_flagged_alert_msg_first_part")}
            &nbsp;
            <Link
              href="https://www.tokhimo.com/jobs-faq"
              target="_blank"
              color="inherit"
              rel="noopener noreferrer">
              {intl.get("t_general_click_here")}
            </Link>
            &nbsp;
            {intl.get("t_job_status_flagged_alert_msg_second_part")}
          </Typography>
        )
      };
    case JOB_POSTING_STATUS.SUBMITTED_FOR_AUTO_REVIEW:
    case JOB_POSTING_STATUS.SUBMITTED_FOR_MANUAL_REVIEW:
      return {
        severity: "warning",
        message: (
          <Typography variant="body1">
            {intl.get("t_job_status_under_review_alert_msg")}
          </Typography>
        )
      };
    default:
      return { severity: "success", message: "" };
  }
};

const JobStatusAlert = ({
  status,
  jobId = "",
  intl = globalIntl
}: JobStatusAlert) => {
  const { severity, message } = getAlertDataByJobStatus(status, jobId, intl);
  return (
    <StyledAlert severity={severity} variant="filled" icon={<InfoIcon />}>
      <Typography variant="body1">{message}</Typography>
      &nbsp;
    </StyledAlert>
  );
};

export default JobStatusAlert;
