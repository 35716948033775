import { useEffect, useState } from "react";

import { useForm, UseFormSetValue } from "react-hook-form";
import { useParams } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import { ContentState, convertToRaw, RawDraftContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import * as yup from "yup";

import { AutoSuggestionDialogState } from "@pages/EmployersPostJob/JobInformation";
import { JobInformationContentResponse } from "@pages/EmployersPostJob/JobInformation/JobInformationFormDialog";

import Button from "@components/Button";
import TextField from "@components/TextField";

import useToast from "@hooks/useToast";

import {
  FREE_TEXT_FIELD_MAX_LENGTH,
  JOB_INFORMATION_SECTION_TYPE
} from "@utils/config";
import { rawDraftContentStateToHTML } from "@utils/convertDraftContentState";
import { functions } from "@utils/firebase";
import translate, { intl } from "@utils/translate";

interface JobInformationPromptInputFormProps {
  jobTitle: string;
  companyName: string;
  autoSuggestionDialog: AutoSuggestionDialogState;
  promptId: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setJobInfoFieldValue: UseFormSetValue<any>;
  oldContent?: RawDraftContentState;
  isEdit?: boolean;
}

const JobInformationPromptInputForm = ({
  companyName,
  jobTitle,
  autoSuggestionDialog,
  setJobInfoFieldValue,
  promptId,
  oldContent,
  isEdit = false
}: JobInformationPromptInputFormProps) => {
  const [
    jobInformationContent,
    isJobInformationContentLoading,
    jobInformationContentError
  ] = useHttpsCallable(functions, "getJobInformationContent");
  const toast = useToast();
  const { data_locale_short: dataLocaleShort } = useParams();

  const [currentPromptId, setCurrentPromptId] = useState<string>(
    promptId ?? ""
  );
  const currentDataLocale = translate.getLocaleFromShort(dataLocaleShort);

  const schema = yup.object({
    promptInput: yup
      .string()
      .trim()
      .max(
        FREE_TEXT_FIELD_MAX_LENGTH,
        intl.get("t_error_max_limit", {
          field: intl.get(
            "t_job_post_job_information_auto_generation_prompt_input_label"
          ),
          maxLimit: FREE_TEXT_FIELD_MAX_LENGTH
        })
      )
      .required(
        intl.get("t_error_required", {
          field: intl.get(
            "t_job_post_job_information_auto_generation_prompt_input_label"
          )
        })
      )
  });

  const formInitValues = {
    promptInput: ""
  };

  const methods = useForm({
    defaultValues: formInitValues,
    resolver: yupResolver(schema)
  });

  const { handleSubmit, control } = methods;

  useEffect(() => {
    if (jobInformationContentError) {
      toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
    }
  }, [jobInformationContentError]);

  const handleGenerateButton = async (formData: { promptInput: string }) => {
    try {
      const res = await jobInformationContent({
        jobData: {
          companyName: companyName,
          jobTitle: jobTitle,
          jobInformationSectionType:
            autoSuggestionDialog.openFor === "job-description"
              ? JOB_INFORMATION_SECTION_TYPE.JOB_DESCRIPTION
              : JOB_INFORMATION_SECTION_TYPE.MUST_REQUIREMENT
        },
        promptData: {
          promptId: currentPromptId,
          promptInput: formData.promptInput,
          ...(isEdit && !currentPromptId && oldContent
            ? { oldContent: rawDraftContentStateToHTML(oldContent) }
            : {})
        },
        locale: currentDataLocale
      });
      const responseData = res?.data as JobInformationContentResponse;
      if (responseData.promptId) {
        setCurrentPromptId(responseData.promptId);
      }
      const blocksFromHtml = htmlToDraft(responseData.content);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      const rawContentState = convertToRaw(contentState);
      setJobInfoFieldValue("generatedContent", rawContentState);
    } catch (error) {
      toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
    }
  };

  return (
    <Box
      noValidate
      component="form"
      id="job-information-prompt-form"
      onSubmit={(e) => {
        e.stopPropagation();
        return handleSubmit(handleGenerateButton)(e);
      }}>
      <TextField
        multiline
        rows={2}
        control={control}
        name="promptInput"
        label={intl.get(
          "t_job_post_job_information_auto_generation_prompt_input_label"
        )}
        placeholder={intl.get(
          "t_job_post_job_information_auto_generation_prompt_input_placeholder"
        )}
      />
      <Box mb={2}>
        <Button
          type="submit"
          formId="job-information-prompt-form"
          loading={isJobInformationContentLoading}>
          {intl.get("t_general_generate")}
        </Button>
      </Box>
    </Box>
  );
};

export default JobInformationPromptInputForm;
