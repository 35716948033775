import { ChangeEvent, useCallback, useEffect, useState } from "react";

import { useSearchParams } from "react-router-dom";

import { Box, Grid } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";

import SkeletonAppliedJobCard from "@skeletons/SkeletonAppliedJobCard";

import AppliedJobCard from "@components/JobCards/AppliedJobCard";
import NoJobsCard from "@components/NoJobsCard";
import Pagination from "@components/Pagination";

import useUserProfile from "@hooks/database/useUserProfile";
import useToast from "@hooks/useToast";

import ApplicationID from "@interfaces/database/ApplicationID";
import AppliedInvitedJob from "@interfaces/database/AppliedInvitedJob";
import CompanyProfile from "@interfaces/database/CompanyProfile";
import HiringFlow from "@interfaces/database/HiringFlow";
import JobApplicationInvitation from "@interfaces/database/JobApplicationInvitation";
import JobID from "@interfaces/database/JobID";
import JobProfile from "@interfaces/database/JobProfile";
import MultiLingual from "@interfaces/database/MultiLingual";

import {
  FILES_LOCATION_COMPANY_LOGO,
  FIRESTORE_COLLECTIONS,
  JOB_APPLICATION_INVITATION_ACTION_TYPE,
  JOB_POSTING_STATUS,
  JOB_REMOTE_WORK_TYPE,
  JOB_REMOTE_WORK_TYPE_T_LABELS,
  JOB_VISA_SPONSORSHIP_AVAILABLE,
  LANGUAGE_PROFICIENCY,
  MIN_YEARS_OF_EXPERIENCE_T_LABELS,
  PAGINATION
} from "@utils/config";
import { auth, db } from "@utils/firebase";
import { getFileURL } from "@utils/getFileURL";
import { resolveMultiLingual } from "@utils/multiLingual";
import { handleJobRevoke } from "@utils/revokeJob";
import { intl } from "@utils/translate";

interface AppliedJob extends AppliedInvitedJob {
  applicationDeclinedOrRevokedReasons?: Array<MultiLingual<string>>;
  hiringFlowActiveStepKey?: string;
  hiringFlow?: HiringFlow;
  job: JobProfile;
  company: CompanyProfile;
  isLoading?: boolean;
}

const AppliedJobs = () => {
  const [user] = useAuthState(auth);
  const userProfile = useUserProfile();
  const [searchParams, setSearchParams] = useSearchParams();
  const toast = useToast();

  // Job Data Variables
  const jobApplications = [
    ...(userProfile.value?.jobs?.applied || [])
  ].reverse();
  const [appliedJobsData, setAppliedJobsData] = useState<Array<AppliedJob>>([]);

  // Conditional Variables
  const [loading, setLoading] = useState<boolean>(false);
  const [jobIdChecked, setJobIdChecked] = useState<boolean>(false);

  // Pagination Variables
  const itemsPerPage = PAGINATION.ITEMS_PER_PAGE;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const noOfPages = Math.ceil(jobApplications.length / itemsPerPage);

  useEffect(() => {
    // To check if page number is existing and valid
    if (searchParams.has("page")) {
      const page = Number(searchParams.get("page"));

      if (Number.isInteger(page) && page > 0 && page <= noOfPages) {
        setCurrentPage(page);
      } else {
        setCurrentPage(1);
        searchParams.delete("page");
        setSearchParams(searchParams.toString(), { replace: true });
      }
    }

    // To check if job_id is existing and valid
    if (searchParams.has("job_id") && jobApplications.length > 0) {
      const jobId = searchParams.get("job_id");

      // find the index of the job in the array
      const index = jobApplications.findIndex(
        (singleJob) => singleJob?.id === jobId
      );

      // set page number based on jobId index
      if (index !== -1) {
        const page = Math.ceil((index + 1) / itemsPerPage);
        setCurrentPage(page);
        searchParams.set("page", `${page}`);
        setSearchParams(searchParams.toString(), { replace: true });
      } else {
        searchParams.delete("job_id");
        setSearchParams(searchParams.toString(), { replace: true });
      }
    }

    setJobIdChecked(true);
  }, []);

  // To handle page change via pagination
  const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
    searchParams.set("page", `${value}`);
    setSearchParams(searchParams.toString(), { replace: true });
  };

  // Fetch applied jobs based on current page
  useEffect(() => {
    if (jobIdChecked) {
      const jobsPerPage = jobApplications.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
      );
      fetchAppliedJobs(jobsPerPage);
    }
  }, [currentPage, jobIdChecked]);

  const fetchAppliedJobs = useCallback(
    async (applications: Array<AppliedInvitedJob>) => {
      setLoading(true);
      try {
        if (applications.length > 0) {
          const jobsData = [];

          for (const singleApplication of applications) {
            const jobId = singleApplication.id;
            const jobDocRef = doc(db, FIRESTORE_COLLECTIONS.JOBS, jobId);
            const jobDocSnap = await getDoc(jobDocRef);

            if (jobDocSnap.exists()) {
              const jobData = jobDocSnap.data() as JobProfile;
              const companyId = jobData.client_company_id
                ? jobData.client_company_id
                : jobData.company_id;

              const companyDocRef = doc(
                db,
                FIRESTORE_COLLECTIONS.COMPANIES,
                companyId
              );
              const companyDocSnap = await getDoc(companyDocRef);
              let applicationDeclinedOrRevokedReasons;
              let hiringFlowActiveStepKey = "";
              let hiringFlow;
              if (
                singleApplication.status ===
                  JOB_APPLICATION_INVITATION_ACTION_TYPE.DECLINED ||
                singleApplication.status ===
                  JOB_APPLICATION_INVITATION_ACTION_TYPE.REVOKED ||
                singleApplication.status ===
                  JOB_APPLICATION_INVITATION_ACTION_TYPE.HIRING_FLOW_STEP_CHANGED
              ) {
                const docRef = doc(
                  db,
                  `${FIRESTORE_COLLECTIONS.JOBS}/${jobId}/${FIRESTORE_COLLECTIONS.APPLICATIONS}`,
                  singleApplication.application_id
                );
                const docSnap = await getDoc(docRef);

                const applicationData =
                  docSnap.data() as JobApplicationInvitation;

                const lastActionIndex = applicationData.actions.length - 1 ?? 0;
                const lastActionType =
                  applicationData.actions[lastActionIndex].action_type;
                if (
                  lastActionType ===
                    JOB_APPLICATION_INVITATION_ACTION_TYPE.DECLINED ||
                  lastActionType ===
                    JOB_APPLICATION_INVITATION_ACTION_TYPE.REVOKED
                ) {
                  const declinedOrRevokedReasons =
                    applicationData.actions[lastActionIndex].comments;
                  if (
                    declinedOrRevokedReasons &&
                    declinedOrRevokedReasons.length > 0
                  ) {
                    applicationDeclinedOrRevokedReasons =
                      applicationData.actions[lastActionIndex].comments;
                  }
                } else if (
                  lastActionType ===
                  JOB_APPLICATION_INVITATION_ACTION_TYPE.HIRING_FLOW_STEP_CHANGED
                ) {
                  hiringFlowActiveStepKey =
                    applicationData.hiring_flow_active_step_key ?? "";
                  hiringFlow = jobData.hiring_flow;
                }
              }

              if (companyDocSnap.exists()) {
                // Add company data to the job document
                const companyData = companyDocSnap.data();
                const jobWithCompanyData = {
                  ...singleApplication,
                  hiringFlowActiveStepKey,
                  hiringFlow,
                  applicationDeclinedOrRevokedReasons,
                  job: jobData as JobProfile,
                  company: companyData as CompanyProfile
                };
                jobsData.push(jobWithCompanyData);
              }
            }
          }
          // Update state with fetched data
          setAppliedJobsData(jobsData);
        }
      } catch (error) {
        toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
      }
      setLoading(false);
    },
    []
  );

  // Revoke Job
  const handleRevokeSuccess = () => {
    toast.kampai(intl.get("t_toast_revoke_success"), "success");
  };

  const handleRevokeFail = () => {
    toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
  };

  const handleRevokeJob = async (
    applicationId: ApplicationID,
    jobId: JobID,
    jobRevokeReasons?: Array<MultiLingual<string>>
  ) => {
    try {
      const index = appliedJobsData.findIndex(
        (singleJob) => singleJob.id === jobId
      );

      if (index !== -1) {
        const updatedAppliedJobsData = [...appliedJobsData];

        if (applicationId && userProfile.value?.jobs?.applied && user) {
          // Loading state updated
          updatedAppliedJobsData[index].isLoading = true;
          setAppliedJobsData(updatedAppliedJobsData);

          const success = await handleJobRevoke(
            applicationId,
            jobId,
            user?.uid,
            userProfile,
            jobRevokeReasons
          );
          if (success) {
            handleRevokeSuccess();

            //Temporary status update and reason update
            updatedAppliedJobsData[index].status =
              JOB_APPLICATION_INVITATION_ACTION_TYPE.REVOKED;
            updatedAppliedJobsData[index].applicationDeclinedOrRevokedReasons =
              jobRevokeReasons;
          } else {
            handleRevokeFail();
          }
          //Loading state updated
          updatedAppliedJobsData[index].isLoading = false;
          setAppliedJobsData(updatedAppliedJobsData);
        }
      }
    } catch (error) {
      handleRevokeFail();
    }
  };

  return (
    <>
      {userProfile.loading || loading ? (
        // Loading
        <Grid container spacing={3} mt={5.5}>
          <Grid item xs={12} sm={6}>
            <SkeletonAppliedJobCard />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SkeletonAppliedJobCard />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SkeletonAppliedJobCard />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SkeletonAppliedJobCard />
          </Grid>
        </Grid>
      ) : jobApplications?.length === 0 ? (
        // No Jobs Available
        <Box my="20vh">
          <NoJobsCard type="applied" />
        </Box>
      ) : (
        <>
          <Grid container spacing={3} mt={5.5} mb={3}>
            {/* Applied Jobs */}
            {appliedJobsData?.map((singleJob) => {
              const isJobActive =
                singleJob.job.status === JOB_POSTING_STATUS.OK_AUTO_REVIEWED ||
                singleJob.job.status ===
                  JOB_POSTING_STATUS.OK_MANUAL_REVIEWED ||
                singleJob.job.statusV2.en ===
                  JOB_POSTING_STATUS.OK_AUTO_REVIEWED ||
                singleJob.job.statusV2.en ===
                  JOB_POSTING_STATUS.OK_MANUAL_REVIEWED ||
                singleJob.job.statusV2.ja ===
                  JOB_POSTING_STATUS.OK_AUTO_REVIEWED ||
                singleJob.job.statusV2.ja ===
                  JOB_POSTING_STATUS.OK_MANUAL_REVIEWED
                  ? true
                  : false;

              const categories = [];

              if (singleJob?.job?.job_overview?.minimum_experience) {
                categories.push(
                  intl.get(
                    MIN_YEARS_OF_EXPERIENCE_T_LABELS[
                      singleJob?.job?.job_overview
                        ?.minimum_experience as keyof typeof MIN_YEARS_OF_EXPERIENCE_T_LABELS
                    ]
                  )
                );
              }
              if (
                singleJob?.job?.job_overview?.remote_possible ==
                  JOB_REMOTE_WORK_TYPE.FULLY_REMOTE ||
                singleJob?.job?.job_overview?.remote_possible ==
                  JOB_REMOTE_WORK_TYPE.PARTIALLY_REMOTE
              ) {
                categories.push(
                  intl.get(
                    JOB_REMOTE_WORK_TYPE_T_LABELS[
                      singleJob?.job?.job_overview
                        ?.remote_possible as keyof typeof JOB_REMOTE_WORK_TYPE_T_LABELS
                    ]
                  )
                );
              }
              if (
                parseInt(LANGUAGE_PROFICIENCY.BUSINESS) >=
                parseInt(
                  singleJob?.job?.language_requirement?.at(1)?.proficiency ??
                    "0"
                )
              ) {
                categories.push(intl.get("t_job_card_en_tag"));
              }

              if (
                singleJob?.job?.job_overview?.visa_sponsorship ==
                JOB_VISA_SPONSORSHIP_AVAILABLE.YES
              ) {
                categories.push(intl.get("t_job_visa_sponsorship"));
              }

              return (
                <Grid item xs={12} sm={6} key={singleJob.id}>
                  <AppliedJobCard
                    hiringFlowActiveStepKey={singleJob.hiringFlowActiveStepKey}
                    hiringFlow={singleJob.hiringFlow}
                    applicationDeclinedOrRevokedReasons={
                      singleJob.applicationDeclinedOrRevokedReasons
                    }
                    categories={categories}
                    status={[singleJob.status]}
                    timestamp={singleJob?.updated_at.toDate()}
                    jobTitle={resolveMultiLingual(singleJob?.job?.job_title)}
                    companyLogo={getFileURL(
                      FILES_LOCATION_COMPANY_LOGO,
                      singleJob?.job?.client_company_id
                        ? singleJob.job.client_company_id
                        : singleJob?.job?.company_id,
                      singleJob?.company?.logo?.extension,
                      singleJob?.company?.logo?.uploaded_at
                    )}
                    companyName={resolveMultiLingual(singleJob?.company?.name)}
                    location={{
                      city: singleJob?.job?.job_overview?.location.city ?? "-",
                      country:
                        singleJob?.job?.job_overview?.location?.country ?? ""
                    }}
                    isRevoked={
                      singleJob.status ===
                      JOB_APPLICATION_INVITATION_ACTION_TYPE.REVOKED
                    }
                    handleJobRevoke={(
                      jobRevokedReasons?: Array<MultiLingual<string>>
                    ) => {
                      handleRevokeJob(
                        singleJob?.application_id,
                        singleJob.id,
                        jobRevokedReasons
                      );
                    }}
                    handleClick={() => {
                      if (isJobActive) {
                        window.open(
                          `/jobs/${singleJob.id}?hide_search=1`,
                          "_blank",
                          "noreferrer"
                        );
                      }
                    }}
                    isLoading={singleJob?.isLoading}
                    isJobActive={isJobActive}
                    isHighlighted={singleJob.id === searchParams.get("job_id")}
                  />
                </Grid>
              );
            })}
          </Grid>
          {/* Pagination  */}
          <Pagination
            count={noOfPages}
            page={currentPage}
            onChange={handlePageChange}
          />
        </>
      )}
    </>
  );
};

export default AppliedJobs;
