import { ChangeEvent, useEffect, useState } from "react";

import { useSearchParams } from "react-router-dom";

import { Box, Grid } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";

import SkeletonSavedJobCard from "@skeletons/SkeletonSavedJobCard";

import SavedJobCard from "@components/JobCards/SavedJobCard";
import NoJobsCard from "@components/NoJobsCard";
import Pagination from "@components/Pagination";

import useUserProfile from "@hooks/database/useUserProfile";
import useToast from "@hooks/useToast";

import CompanyID from "@interfaces/database/CompanyID";
import CompanyProfile from "@interfaces/database/CompanyProfile";
import JobID from "@interfaces/database/JobID";
import JobProfile from "@interfaces/database/JobProfile";

import { canUserApply, handleJobApply } from "@utils/applyJob";
import { handleJobBookmarkChange } from "@utils/bookmarkJob";
import {
  FILES_LOCATION_COMPANY_LOGO,
  FIRESTORE_COLLECTIONS,
  JOB_ACTIVE_TAG_COMPANY_LAST_LOGIN_DAY,
  JOB_NEW_TAG_LAST_UPDATED_DAY,
  JOB_POSTING_STATUS,
  JOB_REMOTE_WORK_TYPE,
  JOB_REMOTE_WORK_TYPE_T_LABELS,
  JOB_STATUS_TAGS,
  JOB_VISA_SPONSORSHIP_AVAILABLE,
  LANGUAGE_PROFICIENCY,
  MIN_YEARS_OF_EXPERIENCE_T_LABELS,
  PAGINATION
} from "@utils/config";
import { getDaysAgo } from "@utils/dateTime";
import { auth, db } from "@utils/firebase";
import { getFileURL } from "@utils/getFileURL";
import { resolveMultiLingual } from "@utils/multiLingual";
import { intl } from "@utils/translate";

interface BookmarkedJob extends JobProfile {
  jobId: JobID;
  company: CompanyProfile;
  isLoading?: boolean;
}

//FIXME: Optimize API Calls
const BookmarkedJobs = () => {
  const [user] = useAuthState(auth);
  const userProfile = useUserProfile();
  const toast = useToast();
  const [searchParams, setSearchParams] = useSearchParams();

  const bookmarkedJobIds = userProfile.value?.bookmarks?.reverse() ?? [];
  const appliedJobs = userProfile.value?.jobs?.applied || [];
  const [savedJobsData, setSavedJobsData] = useState<Array<BookmarkedJob>>([]);
  const [loading, setLoading] = useState<boolean>(false);

  // Pagination
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage = PAGINATION.ITEMS_PER_PAGE;
  const noOfPages = Math.ceil(bookmarkedJobIds.length / itemsPerPage);
  const [bookmarkedJobsIdsPerPage, setBookmarkedJobsIdsPerPage] = useState<
    Array<string>
  >([]);

  // To check if page number is valid
  useEffect(() => {
    if (searchParams.has("page")) {
      const page = Number(searchParams.get("page"));

      if (Number.isInteger(page) && page > 0 && page <= noOfPages) {
        setCurrentPage(page);
      } else {
        setCurrentPage(1);
        searchParams.delete("page");
        setSearchParams(searchParams.toString(), { replace: true });
      }
    }
  }, []);

  useEffect(() => {
    setBookmarkedJobsIdsPerPage(
      bookmarkedJobIds.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
      )
    );
  }, [currentPage]);

  const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
    searchParams.set("page", `${value}`);
    setSearchParams(searchParams.toString(), { replace: true });
  };

  const fetchSavedJobs = async (jobIds: Array<string>) => {
    setLoading(true);
    try {
      if (jobIds.length > 0) {
        const jobsData = [];

        for (const singleJobId of jobIds) {
          const jobDocRef = doc(db, FIRESTORE_COLLECTIONS.JOBS, singleJobId);
          const jobDocSnap = await getDoc(jobDocRef);

          if (jobDocSnap.exists()) {
            const jobData = jobDocSnap.data();
            const companyId = jobData.client_company_id
              ? jobData.client_company_id
              : jobData.company_id;

            const companyDocRef = doc(
              db,
              FIRESTORE_COLLECTIONS.COMPANIES,
              companyId
            );
            const companyDocSnap = await getDoc(companyDocRef);

            if (companyDocSnap.exists()) {
              const companyData = companyDocSnap.data();
              // Add company data to the job document
              const jobWithCompanyData = {
                ...(jobData as JobProfile),
                jobId: singleJobId,
                company: companyData as CompanyProfile
              };
              jobsData.push(jobWithCompanyData);
            }
          }
        }
        // Process the final array of job documents with company data
        setSavedJobsData(jobsData);
      }
    } catch (error) {
      toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchSavedJobs(bookmarkedJobsIdsPerPage);
  }, [bookmarkedJobsIdsPerPage]);

  const handleApplySuccess = () => {
    toast.kampai(intl.get("t_toast_apply_job_success"), "success");
  };

  const handleApplyFail = () => {
    toast.kampai(intl.get("t_toast_apply_job_error"), "error");
  };

  const handleApplyJob = async (jobId: JobID, companyId: CompanyID) => {
    try {
      if (user && jobId && companyId && userProfile.value?.bookmarks) {
        if (appliedJobs.find((singleJob) => singleJob.id === jobId)) {
          toast.kampai(intl.get("t_toast_apply_duplicate_job_error"), "error");
          return;
        }

        const index = savedJobsData.findIndex(
          (singleJob) => singleJob.jobId === jobId
        );

        if (index !== -1) {
          const updatedSavedJobsData = [...savedJobsData];
          // Loading state updated
          updatedSavedJobsData[index].isLoading = true;
          setSavedJobsData(updatedSavedJobsData);

          const success = await handleJobApply(
            jobId,
            companyId,
            userProfile,
            user
          );
          if (success) {
            handleApplySuccess();
          } else {
            handleApplyFail();
          }
          // Loading state updated
          updatedSavedJobsData[index].isLoading = false;
          setSavedJobsData(updatedSavedJobsData);
        }
      }
    } catch (error) {
      handleApplyFail();
    }
  };

  return (
    <>
      {userProfile.loading || loading ? (
        <Grid container spacing={3} mt={5.5}>
          <Grid item xs={12} sm={6}>
            <SkeletonSavedJobCard />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SkeletonSavedJobCard />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SkeletonSavedJobCard />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SkeletonSavedJobCard />
          </Grid>
        </Grid>
      ) : bookmarkedJobsIdsPerPage?.length === 0 ? (
        <Box my="20vh">
          <NoJobsCard type="saved" />
        </Box>
      ) : (
        <>
          <Grid container spacing={3} mt={5.5} mb={3}>
            {savedJobsData?.map((singleJob) => {
              const isBookmarked = bookmarkedJobIds.includes(singleJob.jobId);
              const status: Array<
                typeof JOB_STATUS_TAGS[keyof typeof JOB_STATUS_TAGS]
              > = [];
              const isJobActive =
                singleJob.status === JOB_POSTING_STATUS.OK_AUTO_REVIEWED ||
                singleJob.status === JOB_POSTING_STATUS.OK_MANUAL_REVIEWED ||
                singleJob.statusV2.en === JOB_POSTING_STATUS.OK_AUTO_REVIEWED ||
                singleJob.statusV2.en ===
                  JOB_POSTING_STATUS.OK_MANUAL_REVIEWED ||
                singleJob.statusV2.ja === JOB_POSTING_STATUS.OK_AUTO_REVIEWED ||
                singleJob.statusV2.ja === JOB_POSTING_STATUS.OK_MANUAL_REVIEWED
                  ? true
                  : false;

              if (
                getDaysAgo(singleJob.updated_at) <= JOB_NEW_TAG_LAST_UPDATED_DAY
              ) {
                status.push(JOB_STATUS_TAGS.NEW);
              }
              if (
                getDaysAgo(singleJob.company.last_login_at) <=
                JOB_ACTIVE_TAG_COMPANY_LAST_LOGIN_DAY
              ) {
                status.push(JOB_STATUS_TAGS.ACTIVE);
              }
              if (singleJob?.client_company_id) {
                status.push(JOB_STATUS_TAGS.AGENCY);
              }

              const categories = [];

              if (singleJob?.job_overview?.minimum_experience) {
                categories.push(
                  intl.get(
                    MIN_YEARS_OF_EXPERIENCE_T_LABELS[
                      singleJob?.job_overview
                        ?.minimum_experience as keyof typeof MIN_YEARS_OF_EXPERIENCE_T_LABELS
                    ]
                  )
                );
              }
              if (
                singleJob?.job_overview?.remote_possible ==
                JOB_REMOTE_WORK_TYPE.FULLY_REMOTE
              ) {
                categories.push(
                  intl.get(
                    JOB_REMOTE_WORK_TYPE_T_LABELS[
                      singleJob?.job_overview
                        ?.remote_possible as keyof typeof JOB_REMOTE_WORK_TYPE_T_LABELS
                    ]
                  )
                );
              }
              if (
                parseInt(LANGUAGE_PROFICIENCY.BUSINESS) >=
                parseInt(
                  singleJob?.language_requirement?.at(1)?.proficiency ?? "0"
                )
              ) {
                categories.push(intl.get("t_job_card_en_tag"));
              }
              if (
                singleJob?.job_overview?.visa_sponsorship ==
                JOB_VISA_SPONSORSHIP_AVAILABLE.YES
              ) {
                categories.push(intl.get("t_job_visa_sponsorship"));
              }

              return (
                <Grid item xs={12} sm={6} key={singleJob.jobId}>
                  <SavedJobCard
                    categories={categories}
                    status={status}
                    isBookmarked={isBookmarked}
                    isApplied={
                      appliedJobs.findIndex(
                        (singleAppliedJob) =>
                          singleAppliedJob.id === singleJob.jobId
                      ) > -1
                    }
                    timestamp={singleJob?.updated_at.toDate()}
                    jobTitle={resolveMultiLingual(singleJob?.job_title)}
                    companyLogo={getFileURL(
                      FILES_LOCATION_COMPANY_LOGO,
                      singleJob?.client_company_id
                        ? singleJob.client_company_id
                        : singleJob?.company_id,
                      singleJob?.company?.logo?.extension,
                      singleJob?.company?.logo?.uploaded_at
                    )}
                    companyName={resolveMultiLingual(singleJob?.company?.name)}
                    location={{
                      city: singleJob?.job_overview?.location.city ?? "",
                      country: singleJob?.job_overview?.location?.country ?? ""
                    }}
                    jobApplyStatusCode={canUserApply(userProfile, user)}
                    handleJobBookmark={() =>
                      user
                        ? handleJobBookmarkChange(
                            singleJob.jobId,
                            isBookmarked,
                            userProfile,
                            user?.uid
                          )
                        : false
                    }
                    handleJobApply={() =>
                      handleApplyJob(singleJob.jobId, singleJob.company_id)
                    }
                    handleClick={() => {
                      if (isJobActive) {
                        window.open(
                          `/jobs/${singleJob.jobId}?hide_search=1`,
                          "_blank",
                          "noreferrer"
                        );
                      }
                    }}
                    isLoading={singleJob?.isLoading}
                    isJobActive={isJobActive}
                    cvFileRequirements={singleJob?.cv_file_requirements}
                  />
                </Grid>
              );
            })}
          </Grid>
          <Pagination
            count={noOfPages === 0 ? 1 : noOfPages}
            page={currentPage}
            onChange={handlePageChange}
          />
        </>
      )}
    </>
  );
};

export default BookmarkedJobs;
