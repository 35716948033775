import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  Info as InfoIcon,
  NavigateBefore as NavigateBeforeIcon,
  NavigateNext as NavigateNextIcon
} from "@mui/icons-material";
import { Box, Stack } from "@mui/material";
import deepEqual from "deep-equal";
import { ContentState, convertToRaw, RawDraftContentState } from "draft-js";
import { deleteObject, ref } from "firebase/storage";
import { useUploadFile } from "react-firebase-hooks/storage";
import * as yup from "yup";

import SkeletonAdditionalInformation from "@skeletons/EmployersPostJob/SkeletonAdditionalInformation";
import SkeletonButton from "@skeletons/SkeletonButton";

import Button from "@components/Button";
import Checkbox from "@components/Checkbox";
import Paper from "@components/Paper";
import Typography from "@components/Typography";
import UploadJobDetailCard from "@components/UploadJobDetailCard";
import WYSIWYG from "@components/WYSIWYG";

import useCompanyDetails from "@hooks/database/useCompanyDetails";
import useToast from "@hooks/useToast";

import CvFileRequirements from "@interfaces/database/CvFileRequirements";
import JobID from "@interfaces/database/JobID";
import JobProfile from "@interfaces/database/JobProfile";
import UploadedFileInformation from "@interfaces/database/UploadedFileInformation";

import {
  CV_FILE_REQUIREMENTS_CHECKBOX_VALUES,
  FILES_LOCATION_JOB_ADDITIONAL_FILE,
  JOB_POSTING_STATUS,
  OTHER_DETAILS_FIELD_MAX_LENGTH,
  TOTAL_JOB_POSTING_STEPS,
  WYSIWYG_URL_REGEX
} from "@utils/config";
import { rawDraftContentStateToString } from "@utils/convertDraftContentState";
import { storage } from "@utils/firebase";
import getFileMetaInformation from "@utils/getFileMetaInformation";
import { prepareMultiLingual, resolveMultiLingual } from "@utils/multiLingual";
import Timestamp from "@utils/Timestamp";
import translate, { intl } from "@utils/translate";

interface AdditionalInformationFromData {
  otherDetails: RawDraftContentState;
  cvFileRequirements: Array<
    | typeof CV_FILE_REQUIREMENTS_CHECKBOX_VALUES.EN_CV_CHECKED
    | typeof CV_FILE_REQUIREMENTS_CHECKBOX_VALUES.JA_CV_CHECKED
    | typeof CV_FILE_REQUIREMENTS_CHECKBOX_VALUES.JA_RIREKISHO_CHECKED
  >;
}

const AdditionalInformation = () => {
  const { job_id: jobId, data_locale_short: dataLocaleShort } = useParams();
  const navigate = useNavigate();
  const companyDetails = useCompanyDetails();
  const {
    jobData,
    handleSetJobData
  }: {
    jobData: JobProfile;
    handleSetJobData: (
      jobId: JobID,
      jobData: JobProfile,
      handleJobUpdateSuccess: (jobId: string) => void,
      handleJobUpdateFail: () => void
    ) => void;
  } = useOutletContext();
  const toast = useToast();
  const [uploadFile] = useUploadFile();
  const currentDataLocale = translate.getLocaleFromShort(dataLocaleShort);

  const [jobDescriptionFileMetaData, setJobDescriptionFileMetaData] = useState<
    UploadedFileInformation | undefined
  >(jobData?.additional_file);
  const [jobDescriptionFile, setJobDescriptionFile] = useState<File>();
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  useEffect(() => {
    setJobDescriptionFileMetaData(jobData?.additional_file);
  }, [jobData?.additional_file]);

  const isCompanyDetailsLoading = companyDetails.loading;

  // validation schema
  const schema = yup.object({
    otherDetails: yup
      .object()
      .shape({
        blocks: yup.array().of(yup.object()).min(1),
        entityMap: yup.object()
      })
      .test(
        "otherDetails",
        intl.get("t_error_max_limit", {
          field: intl.get(
            "t_job_post_job_additional_information_step_other_details"
          ),
          maxLimit: OTHER_DETAILS_FIELD_MAX_LENGTH
        }),
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        (rawDraftState: any) =>
          rawDraftContentStateToString(rawDraftState).length <=
          OTHER_DETAILS_FIELD_MAX_LENGTH
      )
      .test(
        "otherDetails",
        intl.get("t_error_urls_are_prohibited"),
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        (rawDraftState: any) => {
          const isLinkNotFound = Object.values(rawDraftState.entityMap).every(
            // eslint-disable-next-line  @typescript-eslint/no-explicit-any
            (singleNode: any) => {
              singleNode.type !== "LINK";
            }
          );
          return isLinkNotFound;
        }
      )
      .test(
        "otherDetails",
        intl.get("t_error_urls_are_prohibited"),
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        (rawDraftState: any) => {
          const text = rawDraftContentStateToString(rawDraftState);
          return !WYSIWYG_URL_REGEX.test(text);
        }
      ),
    cvFileRequirements: yup.array().test(
      "cvFileRequirements",
      intl.get("t_error_invalid", {
        field: intl.get(
          "t_job_post_job_additional_information_step_required_documents"
        )
      }),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (value: any) => {
        if (
          value?.length === 0 ||
          value?.includes(CV_FILE_REQUIREMENTS_CHECKBOX_VALUES.EN_CV_CHECKED) ||
          value?.includes(CV_FILE_REQUIREMENTS_CHECKBOX_VALUES.JA_CV_CHECKED) ||
          value?.includes(
            CV_FILE_REQUIREMENTS_CHECKBOX_VALUES.JA_RIREKISHO_CHECKED
          )
        ) {
          return true;
        } else {
          return false;
        }
      }
    )
  });

  const cvFileRequirementsInitValue: Array<
    | typeof CV_FILE_REQUIREMENTS_CHECKBOX_VALUES.EN_CV_CHECKED
    | typeof CV_FILE_REQUIREMENTS_CHECKBOX_VALUES.JA_CV_CHECKED
    | typeof CV_FILE_REQUIREMENTS_CHECKBOX_VALUES.JA_RIREKISHO_CHECKED
  > = [];

  if (jobData?.cv_file_requirements?.is_en_cv_mandatory) {
    cvFileRequirementsInitValue.push(
      CV_FILE_REQUIREMENTS_CHECKBOX_VALUES.EN_CV_CHECKED
    );
  }
  if (jobData?.cv_file_requirements?.is_ja_cv_mandatory) {
    cvFileRequirementsInitValue.push(
      CV_FILE_REQUIREMENTS_CHECKBOX_VALUES.JA_CV_CHECKED
    );
  }
  if (jobData?.cv_file_requirements?.is_ja_rirekisho_mandatory) {
    cvFileRequirementsInitValue.push(
      CV_FILE_REQUIREMENTS_CHECKBOX_VALUES.JA_RIREKISHO_CHECKED
    );
  }

  const formInitValues = {
    otherDetails:
      resolveMultiLingual(jobData?.additional_information, currentDataLocale) ??
      convertToRaw(ContentState.createFromText("")),
    cvFileRequirements: cvFileRequirementsInitValue
  };

  const methods = useForm({
    defaultValues: formInitValues,
    resolver: yupResolver(schema)
  });

  const { handleSubmit, control, reset, setValue } = methods;

  useEffect(() => {
    reset(formInitValues);
  }, [jobData?.additional_information, jobData?.cv_file_requirements]);

  const handleJobUpdateSuccess = (updatedJobId: string) => {
    setIsDisabled(false);
    navigate(
      `/${translate.getCurrentLocale()}/employers/jobs/${updatedJobId}/${dataLocaleShort}/hiring-process`
    );
  };

  const handleJobUpdateFail = () => {
    setIsDisabled(false);
    toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
  };

  const handleFileUpload = async (file: File) => {
    try {
      setJobDescriptionFile(file);
      if (jobId && file) {
        const { fileExt, fileName, fileSizeInMb } =
          getFileMetaInformation(file);
        setJobDescriptionFileMetaData({
          name: fileName,
          extension: fileExt,
          size: fileSizeInMb,
          uploaded_at: Timestamp.now()
        });
      }
    } catch (e) {
      toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
    }
  };

  const handleFileDelete = async () => {
    try {
      if (jobId) {
        const storageRef = ref(
          storage,
          `${FILES_LOCATION_JOB_ADDITIONAL_FILE}/${jobId}.${jobDescriptionFileMetaData?.extension}`
        );

        // TODO: consider soft delete scenario in future
        await deleteObject(storageRef);
        if (jobData?.additional_file) {
          delete jobData.additional_file;
        }
        if (jobId && companyDetails?.value?.jobs) {
          handleSetJobData(
            jobId,
            jobData,
            (updatedJobId) => {
              if (updatedJobId) {
                setJobDescriptionFile(undefined);
                setJobDescriptionFileMetaData(undefined);
                toast.kampai(
                  intl.get("t_toast_success_delete_job_detail_file"),
                  "success"
                );
              }
            },
            handleJobUpdateFail
          );
        }
      }
    } catch (e) {
      toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
    }
  };

  const handleFormSubmit = async (formData: AdditionalInformationFromData) => {
    try {
      setIsDisabled(true);
      const { otherDetails, cvFileRequirements } = formData || {};
      if (companyDetails?.value && !companyDetails?.value?.jobs) {
        companyDetails.value.jobs = [];
      }

      if (jobId && jobDescriptionFile) {
        const { fileName, fileExt, fileType } =
          getFileMetaInformation(jobDescriptionFile);
        const storageRef = ref(
          storage,
          `${FILES_LOCATION_JOB_ADDITIONAL_FILE}/${jobId}.${fileExt}`
        );
        await uploadFile(storageRef, jobDescriptionFile, {
          contentType: fileType,
          contentDisposition: `attachment; filename=${fileName}`
        });
      }
      const isFormValueChanged = !deepEqual(formData, formInitValues);
      if (
        jobId &&
        companyDetails?.value?.jobs &&
        (jobData.status === JOB_POSTING_STATUS.DRAFT ||
          isFormValueChanged ||
          jobDescriptionFile)
      ) {
        const cvFileRequirementsFlag: CvFileRequirements = {
          is_en_cv_mandatory:
            cvFileRequirements?.includes(
              CV_FILE_REQUIREMENTS_CHECKBOX_VALUES.EN_CV_CHECKED
            ) ?? false,
          is_ja_cv_mandatory:
            cvFileRequirements?.includes(
              CV_FILE_REQUIREMENTS_CHECKBOX_VALUES.JA_CV_CHECKED
            ) ?? false,
          is_ja_rirekisho_mandatory:
            cvFileRequirements?.includes(
              CV_FILE_REQUIREMENTS_CHECKBOX_VALUES.JA_RIREKISHO_CHECKED
            ) ?? false
        };
        handleSetJobData(
          jobId,
          {
            ...jobData,
            ...(otherDetails
              ? {
                  additional_information: prepareMultiLingual(
                    otherDetails,
                    currentDataLocale,
                    jobData.additional_information
                  )
                }
              : {}),
            ...(jobDescriptionFileMetaData
              ? {
                  additional_file: jobDescriptionFileMetaData
                }
              : {}),
            cv_file_requirements: cvFileRequirementsFlag,
            updated_at: Timestamp.now()
          },
          (updatedJobId) => {
            handleJobUpdateSuccess(updatedJobId);
          },
          handleJobUpdateFail
        );
      } else if (jobId) {
        handleJobUpdateSuccess(jobId);
      }
    } catch (e) {
      handleJobUpdateFail();
    }
  };

  return (
    <>
      <Box
        noValidate
        component="form"
        onSubmit={handleSubmit(handleFormSubmit)}>
        <Paper>
          <Stack direction={{ xs: "column", md: "row" }} gap={{ md: 10 }}>
            <Stack width={{ xs: "100%", md: "40%" }}>
              <Typography
                variant="subtitle2"
                mb={{ xs: 3, md: 8 }}
                color="secondary.main">
                {intl.get("t_general_step", { stepNumber: 6 })}/
                {TOTAL_JOB_POSTING_STEPS}
              </Typography>
              <Typography variant="h4" mb={2.5}>
                {intl.get("t_job_post_job_additional_information_step_label")}
              </Typography>
            </Stack>
            <Stack width={{ xs: "100%", md: "60%" }}>
              {isCompanyDetailsLoading ? (
                <SkeletonAdditionalInformation />
              ) : (
                <>
                  <Typography mt={3} mb={1} variant="subtitle4">
                    {intl.get(
                      "t_job_post_job_additional_information_step_required_documents"
                    )}
                  </Typography>
                  <Checkbox
                    data-testid="additional_information_required_documents_checkbox"
                    disabled={isDisabled}
                    size="medium"
                    name="cvFileRequirements"
                    control={control}
                    setValue={setValue}
                    options={[
                      {
                        label: intl.get("t_general_english_cv"),
                        value:
                          CV_FILE_REQUIREMENTS_CHECKBOX_VALUES.EN_CV_CHECKED
                      },
                      {
                        label: intl.get("t_general_japanese_cv"),
                        value:
                          CV_FILE_REQUIREMENTS_CHECKBOX_VALUES.JA_CV_CHECKED
                      },
                      {
                        label: intl.get("t_general_japanese_rirekisho"),
                        value:
                          CV_FILE_REQUIREMENTS_CHECKBOX_VALUES.JA_RIREKISHO_CHECKED
                      }
                    ]}
                  />
                  <Typography mt={3} variant="subtitle4">
                    {intl.get(
                      "t_job_post_job_additional_information_step_other_details"
                    )}
                  </Typography>
                  <Stack direction="row" alignItems="center" columnGap={1}>
                    <InfoIcon color="info" />
                    <Typography variant="subtitle4">
                      {intl.get(
                        "t_job_post_job_additional_information_step_info"
                      )}
                    </Typography>
                  </Stack>
                  <Typography my={1.5} variant="body2" color="text.secondary">
                    {intl.get(
                      "t_job_post_job_additional_information_step_other_details_description"
                    )}
                  </Typography>
                  <Box
                    mb={1}
                    data-testid="additional_information_other_details_box">
                    <WYSIWYG
                      defaultValue={formInitValues.otherDetails}
                      data-testid="additional_information_other_details"
                      disabled={isDisabled}
                      mode="edit"
                      control={control}
                      name="otherDetails"
                      placeholder={intl.get(
                        "t_job_post_job_additional_information_step_other_details_placeholder"
                      )}
                      maxLength={OTHER_DETAILS_FIELD_MAX_LENGTH}
                    />
                  </Box>
                  <UploadJobDetailCard
                    data-testid="additional_information_upload_job_detail_card"
                    disabledActions={isDisabled}
                    fileDetails={jobDescriptionFileMetaData}
                    handleFileUpload={handleFileUpload}
                    handleDelete={handleFileDelete}
                  />
                </>
              )}
            </Stack>
          </Stack>
        </Paper>
        <br />
        <Stack direction="row" justifyContent="space-between">
          {isCompanyDetailsLoading ? (
            <>
              <SkeletonButton />
              <SkeletonButton />
            </>
          ) : (
            <>
              <Button
                data-testid="additional_information_back_button"
                variant="outlined"
                startAdornment={<NavigateBeforeIcon />}
                handleClick={() =>
                  navigate(
                    `/${translate.getCurrentLocale()}/employers/jobs/${jobId}/${dataLocaleShort}/language-requirement`
                  )
                }>
                {intl.get("t_general_back")}
              </Button>
              <Button
                data-testid="additional_information_preview_button"
                loading={isDisabled}
                variant="contained"
                size="large"
                color="primary"
                type="submit"
                endAdornment={<NavigateNextIcon />}>
                {intl.get("t_general_save_and_next")}
              </Button>
            </>
          )}
        </Stack>
      </Box>
    </>
  );
};

export default AdditionalInformation;
