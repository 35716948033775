import { useEffect, useState } from "react";

import { useForm, useWatch } from "react-hook-form";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  CurrencyYen as CurrencyYenIcon,
  NavigateBefore as NavigateBeforeIcon,
  NavigateNext as NavigateNextIcon
} from "@mui/icons-material";
import { Box, Grid, Stack } from "@mui/material";
import deepEqual from "deep-equal";
import * as yup from "yup";

import SkeletonJobOverviewForm from "@skeletons/EmployersPostJob/SkeletonJobOverviewForm";
import SkeletonButton from "@skeletons/SkeletonButton";

import AutoCompleteTextField from "@components/AutoCompleteTextField";
import Button from "@components/Button";
import NonTypeableSelect from "@components/NonTypeableSelect";
import NumberInput from "@components/NumberInput";
import Paper from "@components/Paper";
import Radio from "@components/Radio";
import TypeableSelect from "@components/TypeableSelect";
import Typography from "@components/Typography";

import useCompanyDetails from "@hooks/database/useCompanyDetails";
import { useOptions } from "@hooks/useOptions";
import useToast from "@hooks/useToast";

import KeyLabel from "@interfaces/components/KeyLabel";
import JobID from "@interfaces/database/JobID";
import JobProfile from "@interfaces/database/JobProfile";

import {
  FREE_TEXT_FIELD_MAX_LENGTH,
  JOB_CONTRACT_TYPE,
  JOB_CONTRACT_TYPE_T_LABELS,
  JOB_REMOTE_WORK_TYPE,
  JOB_REMOTE_WORK_TYPE_T_LABELS,
  JOB_SALARY_DEPOSIT_FREQUENCY,
  JOB_SALARY_DEPOSIT_FREQUENCY_T_LABELS,
  JOB_VISA_SPONSORSHIP_AVAILABLE,
  JOB_VISA_SPONSORSHIP_AVAILABLE_T_LABELS,
  JP_ALPHA3_CODE,
  MAX_EXPECTED_SALARY,
  TOTAL_JOB_POSTING_STEPS,
  YEARS_OF_EXPERIENCE,
  YEARS_OF_EXPERIENCE_T_LABELS
} from "@utils/config";
import { getCityList } from "@utils/keyLabelHandlers/location";
import { getCountryName, getDropdownCountryList } from "@utils/location";
import { colorPalette } from "@utils/theme";
import Timestamp from "@utils/Timestamp";
import translate, { intl } from "@utils/translate";

interface JobOverviewFormData {
  country: KeyLabel;
  city: KeyLabel;
  salaryMin: string | number;
  salaryMax: string | number;
  salaryDepositFrequency:
    | typeof JOB_SALARY_DEPOSIT_FREQUENCY[keyof typeof JOB_SALARY_DEPOSIT_FREQUENCY]
    | string;
  visaSponsorShip: typeof JOB_VISA_SPONSORSHIP_AVAILABLE[keyof typeof JOB_VISA_SPONSORSHIP_AVAILABLE];
  minimumYearOfExperience:
    | typeof YEARS_OF_EXPERIENCE[keyof typeof YEARS_OF_EXPERIENCE]
    | string;
  contractType:
    | typeof JOB_CONTRACT_TYPE[keyof typeof JOB_CONTRACT_TYPE]
    | string;
  remote:
    | typeof JOB_REMOTE_WORK_TYPE[keyof typeof JOB_REMOTE_WORK_TYPE]
    | string;
}

const JobOverview = () => {
  const { job_id: jobId, data_locale_short: dataLocaleShort } = useParams();
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const navigate = useNavigate();
  const {
    jobData,
    handleSetJobData
  }: {
    jobData: JobProfile;
    handleSetJobData: (
      jobId: JobID,
      jobData: JobProfile,
      handleJobUpdateSuccess: (jobId: string) => void,
      handleJobUpdateFail: () => void
    ) => void;
  } = useOutletContext();
  const companyDetails = useCompanyDetails();
  const toast = useToast();

  const isCompanyDetailsLoading = companyDetails.loading;

  const CONTRACT_TYPE_OPTIONS = useOptions(
    JOB_CONTRACT_TYPE,
    JOB_CONTRACT_TYPE_T_LABELS
  );

  const YEARS_OF_EXPERIENCE_OPTIONS = useOptions(
    YEARS_OF_EXPERIENCE,
    YEARS_OF_EXPERIENCE_T_LABELS
  );

  const JOB_REMOTE_OPTIONS = useOptions(
    JOB_REMOTE_WORK_TYPE,
    JOB_REMOTE_WORK_TYPE_T_LABELS
  );

  const JOB_SALARY_DEPOSIT_FREQUENCY_OPTIONS = useOptions(
    JOB_SALARY_DEPOSIT_FREQUENCY,
    JOB_SALARY_DEPOSIT_FREQUENCY_T_LABELS
  );

  const JOB_VISA_SPONSORSHIP_AVAILABLE_OPTIONS = useOptions(
    JOB_VISA_SPONSORSHIP_AVAILABLE,
    JOB_VISA_SPONSORSHIP_AVAILABLE_T_LABELS
  ).map((singleOption) => {
    return { label: singleOption.label, value: singleOption.key };
  });

  // validation schema
  const schema = yup.object({
    country: yup
      .object()
      .shape({
        key: yup.string(),
        label: yup.string()
      })
      .test(
        "country",
        intl.get("t_error_required", {
          field: intl.get("t_general_country")
        }),
        (value) => (value?.key && value?.label ? true : false)
      )
      .nullable(),
    city: yup
      .object()
      .shape({
        key: yup.string().trim(),
        label: yup.string().trim()
      })
      .test(
        "label",
        intl.get("t_error_max_limit", {
          field: intl.get("t_general_city"),
          maxLimit: FREE_TEXT_FIELD_MAX_LENGTH
        }),
        (value) => {
          return value && value.label
            ? value.label.length <= FREE_TEXT_FIELD_MAX_LENGTH
            : true;
        }
      )
      .nullable(),
    salaryMin: yup
      .number()
      .min(0)
      .max(
        MAX_EXPECTED_SALARY,
        intl.get("t_error_max_salary_limit", { salary: MAX_EXPECTED_SALARY })
      )
      .nullable()
      .transform((salaryMin) => {
        return isNaN(salaryMin) || salaryMin === null || salaryMin === undefined
          ? null
          : salaryMin;
      }),
    salaryMax: yup
      .number()
      .required(
        intl.get("t_error_required", {
          field: intl.get("t_job_post_job_overview_information_step_salary")
        })
      )
      .min(0)
      .max(
        MAX_EXPECTED_SALARY,
        intl.get("t_error_max_salary_limit", { salary: MAX_EXPECTED_SALARY })
      )
      .nullable()
      .transform((salaryMax) => {
        return isNaN(salaryMax) || salaryMax === null || salaryMax === undefined
          ? null
          : salaryMax;
      })
      .test(
        "salaryMax",
        intl.get(
          "t_job_post_job_overview_information_step_salary_max_validation_msg"
        ),
        function (salaryMax) {
          const { salaryMin } = this.parent;
          return !salaryMin || !salaryMax || salaryMax >= salaryMin;
        }
      ),
    salaryDepositFrequency: yup
      .string()
      .nullable()
      .required(
        intl.get("t_error_required", {
          field: intl.get(
            "t_job_post_job_overview_information_step_salary_deposit_frequency"
          )
        })
      ),
    visaSponsorShip: yup.string().required(
      intl.get("t_error_required", {
        field: intl.get(
          "t_job_post_job_overview_information_step_visa_sponsorship"
        )
      })
    ),
    minimumYearOfExperience: yup
      .string()
      .nullable()
      .required(
        intl.get("t_error_required", {
          field: intl.get(
            "t_job_post_job_overview_information_step_minimum_year_of_experience"
          )
        })
      ),
    contractType: yup
      .string()
      .nullable()
      .required(
        intl.get("t_error_required", {
          field: intl.get(
            "t_job_post_job_overview_information_step_contract_type"
          )
        })
      ),
    remote: yup
      .string()
      .nullable()
      .required(
        intl.get("t_error_required", {
          field: intl.get("t_job_post_job_overview_information_step_remote")
        })
      )
  });

  const formInitValues = {
    country: {
      key: jobData?.job_overview?.location?.country ?? JP_ALPHA3_CODE,
      label:
        getCountryName(
          jobData?.job_overview?.location?.country ?? JP_ALPHA3_CODE
        ) ?? ""
    },
    city: {
      key: jobData?.job_overview?.location?.city ?? "", // FIXME: handle i18 logic
      label: jobData?.job_overview?.location?.city ?? ""
    },
    salaryMin: jobData?.job_overview?.salary?.min ?? "",
    salaryMax: jobData?.job_overview?.salary?.max ?? "",
    salaryDepositFrequency: jobData?.job_overview?.salary?.frequency ?? "",
    visaSponsorShip:
      jobData?.job_overview?.visa_sponsorship ??
      JOB_VISA_SPONSORSHIP_AVAILABLE.NO,
    minimumYearOfExperience: jobData?.job_overview?.minimum_experience ?? "",
    contractType: jobData?.job_overview?.contract_type ?? "",
    remote: jobData?.job_overview?.remote_possible ?? ""
  };

  const methods = useForm({
    defaultValues: formInitValues,
    resolver: yupResolver(schema)
  });

  const { handleSubmit, control, setValue, reset, formState } = methods;

  const selectedCountryIsoCode = useWatch({
    control,
    name: "country.key"
  });

  useEffect(() => {
    // check selected country is modified or not
    if (formState.dirtyFields.country) {
      setValue("city", { key: "", label: "" });
    }
  }, [selectedCountryIsoCode, setValue]);

  const COUNTRY_OPTIONS = getDropdownCountryList();

  useEffect(() => {
    reset(formInitValues);
  }, [jobData?.job_overview]);

  const handleJobUpdateSuccess = (updatedJobId: string) => {
    setIsDisabled(false);
    navigate(
      `/${translate.getCurrentLocale()}/employers/jobs/${updatedJobId}/${dataLocaleShort}/language-requirement`
    );
  };

  const handleJobUpdateFail = () => {
    setIsDisabled(false);
    toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
  };

  const handleFormSubmit = async (formData: JobOverviewFormData) => {
    const isFormValueChanged = !deepEqual(formData, formInitValues);
    setIsDisabled(true);
    try {
      const {
        country,
        city,
        salaryMin,
        salaryMax,
        salaryDepositFrequency,
        visaSponsorShip,
        minimumYearOfExperience,
        contractType,
        remote
      } = formData || {};
      if (companyDetails?.value && !companyDetails?.value?.jobs) {
        companyDetails.value.jobs = [];
      }
      if (jobId && companyDetails?.value?.jobs && isFormValueChanged) {
        handleSetJobData(
          jobId,
          {
            ...jobData,
            job_overview: {
              location: {
                city: city?.key,
                country: country?.key
              },
              salary: {
                min: salaryMin ? Number(salaryMin) : 0,
                max: Number(salaryMax),
                frequency:
                  salaryDepositFrequency as typeof JOB_SALARY_DEPOSIT_FREQUENCY[keyof typeof JOB_SALARY_DEPOSIT_FREQUENCY]
              },
              visa_sponsorship:
                visaSponsorShip as typeof JOB_VISA_SPONSORSHIP_AVAILABLE[keyof typeof JOB_VISA_SPONSORSHIP_AVAILABLE],
              minimum_experience:
                minimumYearOfExperience as typeof YEARS_OF_EXPERIENCE[keyof typeof YEARS_OF_EXPERIENCE],
              contract_type:
                contractType as typeof JOB_CONTRACT_TYPE[keyof typeof JOB_CONTRACT_TYPE],
              remote_possible:
                remote as typeof JOB_REMOTE_WORK_TYPE[keyof typeof JOB_REMOTE_WORK_TYPE]
            },
            updated_at: Timestamp.now()
          },
          handleJobUpdateSuccess,
          handleJobUpdateFail
        );
      } else if (jobId) {
        handleJobUpdateSuccess(jobId);
      }
    } catch (e) {
      handleJobUpdateFail();
    }
  };

  return (
    <>
      <Box
        noValidate
        component="form"
        onSubmit={handleSubmit(handleFormSubmit)}>
        <Paper>
          <Stack direction={{ xs: "column", md: "row" }} gap={{ md: 10 }}>
            <Stack width={{ xs: "100%", md: "40%" }}>
              <Typography
                variant="subtitle2"
                mb={{ xs: 3, md: 8 }}
                color="secondary.main">
                {intl.get("t_general_step", { stepNumber: 4 })}/
                {TOTAL_JOB_POSTING_STEPS}
              </Typography>
              <Typography variant="h4" mb={2.5}>
                {intl.get("t_job_post_job_overview_information_step_label")}
              </Typography>
            </Stack>
            <Stack width={{ xs: "100%", md: "60%" }}>
              {isCompanyDetailsLoading ? (
                <SkeletonJobOverviewForm />
              ) : (
                <Grid container columnSpacing={3}>
                  <Grid item xs={12} md={6}>
                    <TypeableSelect
                      data-testid="job_overview_information_country_select"
                      disabled={isDisabled}
                      control={control}
                      name="country"
                      label={intl.get("t_general_country")}
                      placeholder={intl.get(
                        "t_job_post_job_overview_information_step_country_placeholder"
                      )}
                      required
                      options={COUNTRY_OPTIONS}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <AutoCompleteTextField
                      data-testid="job_overview_information_city_input"
                      control={control}
                      disabled={!selectedCountryIsoCode || isDisabled}
                      name="city"
                      label={intl.get("t_general_city")}
                      placeholder={intl.get(
                        "t_job_post_job_overview_information_step_city_placeholder"
                      )}
                      setValue={setValue}
                      getOptions={(subText) => {
                        return getCityList(subText, selectedCountryIsoCode);
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <NumberInput
                      data-testid="job_overview_information_salary_min_input"
                      disabled={isDisabled}
                      control={control}
                      thousandSeparator
                      startAdornment={
                        <CurrencyYenIcon
                          htmlColor={colorPalette.black.base}
                          fontSize="small"
                        />
                      }
                      name="salaryMin"
                      label={intl.get(
                        "t_job_post_job_overview_information_step_salary"
                      )}
                      placeholder={intl.get(
                        "t_job_post_job_overview_information_step_salary_min_placeholder"
                      )}
                      required
                    />
                  </Grid>
                  <Grid item xs={6} mt={{ xs: 3, md: 3 }}>
                    <NumberInput
                      data-testid="job_overview_information_salary_max_input"
                      disabled={isDisabled}
                      control={control}
                      thousandSeparator
                      startAdornment={
                        <CurrencyYenIcon
                          htmlColor={colorPalette.black.base}
                          fontSize="small"
                        />
                      }
                      name="salaryMax"
                      placeholder={intl.get(
                        "t_job_post_job_overview_information_step_salary_max_placeholder"
                      )}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <NonTypeableSelect
                      data-testid="job_overview_information_salary_frequency_select"
                      setValue={setValue}
                      disabled={isDisabled}
                      control={control}
                      name="salaryDepositFrequency"
                      placeholder={intl.get(
                        "t_job_post_job_overview_information_step_salary_deposit_frequency_placeholder"
                      )}
                      required
                      options={JOB_SALARY_DEPOSIT_FREQUENCY_OPTIONS}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    pt={1.5}
                    sx={{ rowGap: 0.375, display: "grid" }}>
                    <Radio
                      data-testid="job_overview_information_visa_sponsorship_radio"
                      disabled={isDisabled}
                      control={control}
                      name="visaSponsorShip"
                      label={intl.get(
                        "t_job_post_job_overview_information_step_visa_sponsorship"
                      )}
                      direction="row"
                      required
                      options={JOB_VISA_SPONSORSHIP_AVAILABLE_OPTIONS}
                    />
                  </Grid>
                  <Grid item xs={12} pt={2}>
                    <NonTypeableSelect
                      data-testid="job_overview_information_minimum_year_of_experience_select"
                      setValue={setValue}
                      control={control}
                      disabled={isDisabled}
                      name="minimumYearOfExperience"
                      label={intl.get(
                        "t_job_post_job_overview_information_step_minimum_year_of_experience"
                      )}
                      placeholder={intl.get(
                        "t_job_post_job_overview_information_step_minimum_year_of_experience"
                      )}
                      required
                      options={YEARS_OF_EXPERIENCE_OPTIONS}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <NonTypeableSelect
                      data-testid="job_overview_information_contract_type_select"
                      setValue={setValue}
                      control={control}
                      disabled={isDisabled}
                      name="contractType"
                      label={intl.get(
                        "t_job_post_job_overview_information_step_contract_type"
                      )}
                      placeholder={intl.get(
                        "t_job_post_job_overview_information_step_contract_type"
                      )}
                      required
                      options={CONTRACT_TYPE_OPTIONS}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <NonTypeableSelect
                      data-testid="job_overview_information_remote_select"
                      setValue={setValue}
                      control={control}
                      name="remote"
                      disabled={isDisabled}
                      label={intl.get(
                        "t_job_post_job_overview_information_step_remote"
                      )}
                      placeholder={intl.get(
                        "t_job_post_job_overview_information_step_remote"
                      )}
                      required
                      options={JOB_REMOTE_OPTIONS}
                    />
                  </Grid>
                </Grid>
              )}
            </Stack>
          </Stack>
        </Paper>

        <br />
        <Stack direction="row" justifyContent="space-between">
          {isCompanyDetailsLoading ? (
            <>
              <SkeletonButton />
              <SkeletonButton />
            </>
          ) : (
            <>
              <Button
                data-testid="job_overview_information_back_button"
                variant="outlined"
                startAdornment={<NavigateBeforeIcon />}
                handleClick={() =>
                  navigate(
                    `/${translate.getCurrentLocale()}/employers/jobs/${jobId}/${dataLocaleShort}/skill-requirement`
                  )
                }>
                {intl.get("t_general_back")}
              </Button>
              <Button
                data-testid="job_overview_information_next_button"
                loading={isDisabled}
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                endAdornment={<NavigateNextIcon />}>
                {intl.get("t_general_save_and_next")}
              </Button>
            </>
          )}
        </Stack>
      </Box>
    </>
  );
};

export default JobOverview;
