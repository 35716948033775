import { useEffect, useState } from "react";

import { RawDraftContentState } from "react-draft-wysiwyg";
import { Link } from "react-router-dom";

import {
  Apartment as ApartmentIcon,
  PinDrop as PinDropIcon,
  Work as WorkIcon
} from "@mui/icons-material";
import {
  Box,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  Link as MuiLink,
  Paper,
  Stack,
  styled,
  Tooltip
} from "@mui/material";
import dayjs from "dayjs";
import relative from "dayjs/plugin/relativeTime";
import { ContentState, convertFromRaw, convertToRaw } from "draft-js";
import { ref } from "firebase/storage";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDownloadURL } from "react-firebase-hooks/storage";

import SkeletonKeyword from "@skeletons/SkeletonKeyword";

import ApplyButtonTooltipTitle from "@components/ApplyButtonTooltipTitle";
import Button from "@components/Button";
import CompanyLogo from "@components/CompanyLogo";
import CVFilledStatus from "@components/CVFilledStatus";
import Dialog from "@components/Dialog";
import Icon from "@components/Icon";
import JobDescriptionIcon from "@components/JobDescriptionIcon";
import Keyword from "@components/Keyword";
import Status from "@components/Status";
import Typography from "@components/Typography";
import WYSIWYG from "@components/WYSIWYG";

import useUserProfile from "@hooks/database/useUserProfile";
import useToast from "@hooks/useToast";

import CvFileRequirements from "@interfaces/database/CvFileRequirements";
import HiringFlow from "@interfaces/database/HiringFlow";
import Intl from "@interfaces/database/Intl";
import JobID from "@interfaces/database/JobID";
import Language from "@interfaces/database/Language";
import Location from "@interfaces/database/Location";
import Skill from "@interfaces/database/Skill";
import UploadedFileInformation from "@interfaces/database/UploadedFileInformation";

import {
  FILES_LOCATION_JOB_ADDITIONAL_FILE,
  JOB_APPLY_STATUS_CODE,
  JOB_CONTRACT_TYPE,
  JOB_CONTRACT_TYPE_T_LABELS,
  JOB_HIRING_FLOW_INTERVIEW,
  JOB_HIRING_FLOW_INTERVIEW_T_LABELS,
  JOB_HIRING_FLOW_OFFER,
  JOB_HIRING_FLOW_OFFER_T_LABELS,
  JOB_HIRING_FLOW_RESUME_SCREENING,
  JOB_HIRING_FLOW_RESUME_SCREENING_T_LABELS,
  JOB_REMOTE_WORK_TYPE,
  JOB_REMOTE_WORK_TYPE_T_LABELS,
  JOB_SALARY_DEPOSIT_FREQUENCY,
  JOB_SALARY_DEPOSIT_FREQUENCY_T_LABELS,
  JOB_STATUS_TAGS,
  JOB_VISA_SPONSORSHIP_AVAILABLE,
  JOB_VISA_SPONSORSHIP_AVAILABLE_T_LABELS,
  LANGUAGE_PROFICIENCY,
  LANGUAGE_PROFICIENCY_T_LABELS,
  LOCALE,
  USER_TYPE,
  YEARS_OF_EXPERIENCE,
  YEARS_OF_EXPERIENCE_T_LABELS
} from "@utils/config";
import { auth, envOptions, storage } from "@utils/firebase";
import { getSkillLabel } from "@utils/keyLabelHandlers/skill";
import { getLanguageName } from "@utils/language";
import { getFullLocation } from "@utils/location";
import { roundSalary } from "@utils/roundSalary";
import theme, { colorPalette } from "@utils/theme";
import translate, { intl as globalIntl } from "@utils/translate";

dayjs.extend(relative);

export interface JobDetailCardProps {
  jobId: JobID;
  updatedDate: Date;
  jobStatuses?: Array<typeof JOB_STATUS_TAGS[keyof typeof JOB_STATUS_TAGS]>;
  isBookmark?: boolean;
  handleBookmarkChange?: (isBookmarked: boolean) => void;
  companyLogo?: string;
  companyName?: string;
  companyWebsite?: string;
  agencyName?: string;
  isApplied?: boolean;
  handleApply?: () => void;
  jobTitle?: string;
  location?: Location;
  salary?: {
    min?: number;
    max: number;
    frequency: typeof JOB_SALARY_DEPOSIT_FREQUENCY[keyof typeof JOB_SALARY_DEPOSIT_FREQUENCY];
  };
  visaSponsorship?: typeof JOB_VISA_SPONSORSHIP_AVAILABLE[keyof typeof JOB_VISA_SPONSORSHIP_AVAILABLE];
  experienceRequirement?: typeof YEARS_OF_EXPERIENCE[keyof typeof YEARS_OF_EXPERIENCE];
  cvFileRequirements?: CvFileRequirements;
  jobContractType?: typeof JOB_CONTRACT_TYPE[keyof typeof JOB_CONTRACT_TYPE];
  remoteAllowed?: typeof JOB_REMOTE_WORK_TYPE[keyof typeof JOB_REMOTE_WORK_TYPE];
  jaProficiency?: typeof LANGUAGE_PROFICIENCY[keyof typeof LANGUAGE_PROFICIENCY];
  enProficiency?: typeof LANGUAGE_PROFICIENCY[keyof typeof LANGUAGE_PROFICIENCY];
  jobDescription?: RawDraftContentState;
  mustRequirements?: RawDraftContentState;
  languageRequirement?: Array<Language>;
  requiredSkills?: Array<Skill>;
  otherDetails?: RawDraftContentState;
  jobApplyStatusCode: typeof JOB_APPLY_STATUS_CODE[keyof typeof JOB_APPLY_STATUS_CODE];
  mode?: "employer_preview" | "search_result";
  additionInfoFileDetails?: UploadedFileInformation;
  hiringFlow?: HiringFlow;
  intl?: Intl;
}

export const StyledPaperHeader = styled(Paper)({
  backgroundColor: colorPalette.lightBlue.background.base,
  boxShadow: `0px 3px 5px -1px ${colorPalette.grey[20]}, 0px 6px 10px ${colorPalette.grey[14]}, 0px 1px 18px ${colorPalette.grey[12]}`,
  borderRadius: "4px 4px 0px 0px",
  padding: "0 1.5rem 1.5rem 1.5rem"
});

export const StyledPaperBody = styled(Paper)({
  padding: "1.5rem",
  borderRadius: "0px 0px 4px 4px"
});

export const StyledDownloadButton = styled(Button)({
  textTransform: "none"
});

const StyledIconButton = styled(IconButton)({
  color: "red"
});

export const StyledList = styled(List)({
  "listStyleType": "disc",
  "padding": "0.25rem 1.25rem",
  "& .MuiListItem-root": {
    padding: 0,
    display: "list-item"
  }
});

export const StyledNumberList = styled(List)({
  "listStyleType": "decimal",
  "padding": "0.25rem 1.25rem",
  "& .MuiListItem-root": {
    padding: 0,
    display: "list-item"
  }
});

const JobDetailCard = ({
  jobId,
  updatedDate,
  jobStatuses = [],
  isBookmark = false,
  handleBookmarkChange,
  companyLogo,
  companyName,
  companyWebsite,
  agencyName,
  isApplied = false,
  handleApply,
  jobTitle,
  location,
  salary,
  visaSponsorship,
  experienceRequirement = YEARS_OF_EXPERIENCE.LESS_THAN_ONE_YEAR,
  jobContractType,
  remoteAllowed,
  jobDescription,
  mustRequirements,
  languageRequirement = [],
  jaProficiency,
  enProficiency,
  requiredSkills = [],
  otherDetails,
  jobApplyStatusCode,
  cvFileRequirements,
  mode = "search_result",
  additionInfoFileDetails,
  hiringFlow,
  intl = globalIntl
}: JobDetailCardProps) => {
  const [user] = useAuthState(auth);
  const userProfile = useUserProfile();
  const toast = useToast();
  const [skills, setSkills] = useState<Array<Skill>>([]);
  const [isSkillsLoading, setIsSkillsLoading] = useState<boolean>(true);
  const [downloadFileUrl] =
    additionInfoFileDetails?.extension && jobId
      ? useDownloadURL(
          ref(
            storage,
            `${FILES_LOCATION_JOB_ADDITIONAL_FILE}/${jobId}.${additionInfoFileDetails.extension}`
          )
        )
      : [""];

  const { progress_flags } = userProfile?.value ?? {};

  const isEnResumePresent =
    userProfile?.value?.metadata?.progress_flags?.en_cv ?? false;
  const isJaResumePresent =
    userProfile?.value?.metadata?.progress_flags?.ja_cv ?? false;
  const isJaRirekishoPresent =
    userProfile?.value?.metadata?.progress_flags?.ja_rirekisho ?? false;

  const userProfileStatus = {
    isLoggedIn: user?.uid || userProfile?.value ? true : false,
    isEmailVerified: user?.emailVerified ?? false,
    isProfileSummaryCompleted:
      !progress_flags?.basic_information ||
      !progress_flags?.job_experience_overview ||
      !progress_flags?.language ||
      !progress_flags?.skills
        ? false
        : true,
    isResumeCompleted:
      isEnResumePresent || isJaResumePresent || isJaRirekishoPresent,
    isCandidate:
      userProfile?.value?.user_type === USER_TYPE.INDIVIDUAL ? true : false
  };

  const copyToClipboard = (jobId: string) => {
    navigator.clipboard.writeText(
      `${
        envOptions.frontendUrl
          ? envOptions.frontendUrl
          : "https://" + window.location.hostname
      }/${translate.getCurrentLocale()}/jobs/${jobId}?hide_search=1`
    );
    toast.kampai(intl.get("t_general_link_copied"), "success");
  };

  useEffect(() => {
    (async () => {
      setIsSkillsLoading(true);
      const skills: Array<Skill> = [];
      for (const singleSkill of requiredSkills) {
        const skillLabel = await getSkillLabel(
          singleSkill.name,
          translate.getCurrentLocale()
        );
        skills.push({
          name: skillLabel,
          years_of_experience: singleSkill.years_of_experience
        });
      }
      setSkills(skills);
      setIsSkillsLoading(false);
    })();
  }, []);

  const jobLocation = getFullLocation(location?.city, location?.country, true);

  const jobDescriptionPlainText = jobDescription
    ? convertFromRaw(jobDescription).getPlainText()
    : "";
  const mustRequirementsPlainText = mustRequirements
    ? convertFromRaw(mustRequirements).getPlainText()
    : "";
  const otherDetailsPlainText = otherDetails
    ? convertFromRaw(otherDetails).getPlainText()
    : "";

  const ApplyBtnWithDialog = () => {
    return (
      <Dialog
        title={intl.get("t_dialog_necessary_documents_title")}
        maxWidth="sm"
        initiator={
          <Button
            data-testid="job_detail_card_apply_button"
            size="large"
            fullWidth>
            {intl.get("t_general_apply")}
          </Button>
        }>
        {(_, handleCancel) => (
          <>
            <DialogContent sx={{ py: 0.5 }}>
              <Typography variant="body1" color="text.secondary">
                {intl.get("t_dialog_necessary_documents_subtitle")}
              </Typography>
              <Stack gap={1} mt={3}>
                {cvFileRequirements?.is_en_cv_mandatory ? (
                  <CVFilledStatus
                    label={intl.get("t_general_english_cv")}
                    isAttached={isEnResumePresent}
                  />
                ) : (
                  false
                )}
                {cvFileRequirements?.is_ja_cv_mandatory ? (
                  <CVFilledStatus
                    label={intl.get("t_general_japanese_cv")}
                    isAttached={isJaResumePresent}
                  />
                ) : (
                  false
                )}
                {cvFileRequirements?.is_ja_rirekisho_mandatory ? (
                  <CVFilledStatus
                    label={intl.get("t_general_japanese_rirekisho")}
                    isAttached={isJaRirekishoPresent}
                  />
                ) : (
                  false
                )}
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button
                data-testid="job_detail_card_apply_cancel_button"
                handleClick={handleCancel}
                variant="outlined">
                {intl.get("t_general_cancel")}
              </Button>
              <Button
                data-testid="job_detail_card_apply_button_button"
                handleClick={handleApply}>
                {intl.get("t_general_apply")}
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    );
  };

  return (
    <Paper elevation={0}>
      <StyledPaperHeader>
        <Stack>
          <Grid
            container
            alignItems="center"
            my={1}
            wrap="wrap-reverse"
            rowSpacing={0.5}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" color={colorPalette.black.hover.base}>
                {dayjs(updatedDate)
                  .locale(
                    translate.getLocaleShort(
                      intl.getInitOptions().currentLocale
                    )
                  )
                  .fromNow()}
              </Typography>
            </Grid>
            <Grid item xs="auto" ml="auto">
              <Stack direction="row" gap={1} ml="auto" flexWrap="wrap" mb={1.5}>
                {jobStatuses.map((singleJobStatus, idx) => {
                  return (
                    <Status label={singleJobStatus} key={idx} intl={intl} />
                  );
                })}
                {mode === "search_result" ? (
                  <IconButton
                    disableRipple
                    onClick={() => {
                      copyToClipboard(jobId);
                    }}>
                    <Icon type="share" filled />
                  </IconButton>
                ) : (
                  false
                )}
                {handleBookmarkChange && mode === "search_result" ? (
                  <StyledIconButton
                    disableRipple
                    onClick={() => handleBookmarkChange(isBookmark)}>
                    <Icon type="favorite" filled={isBookmark} />
                  </StyledIconButton>
                ) : (
                  false
                )}
              </Stack>
            </Grid>
          </Grid>
          <Stack
            direction="row"
            gap={1}
            alignItems="center"
            my={1}
            flexWrap="wrap"
            justifyItems="stretch">
            <CompanyLogo src={companyLogo} alt={companyName} />
            <Stack gap={0.5} flexGrow={1}>
              <Stack
                direction="row"
                gap={1}
                color={colorPalette.black.hover.base}>
                <ApartmentIcon color="inherit" />
                <Typography variant="body1">
                  {companyName ? companyName : "-"}
                </Typography>
              </Stack>
              <Stack direction="row" gap={1}>
                <WorkIcon color="inherit" />
                <Typography variant="subtitle2" id="data-for-ssg">
                  {jobTitle ? jobTitle : "-"}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                gap={1}
                color={colorPalette.black.hover.base}>
                <PinDropIcon color="inherit" />
                <Typography
                  variant="body1"
                  color={colorPalette.black.hover.base}
                  textTransform="capitalize">
                  {jobLocation ?? "-"}
                </Typography>
              </Stack>
            </Stack>
            {salary ? (
              <Typography variant="subtitle2">
                ¥
                {salary.min ? (
                  <>
                    &nbsp;
                    {roundSalary(salary.min)}
                    &nbsp;-
                  </>
                ) : (
                  false
                )}
                &nbsp;
                {roundSalary(salary.max)}
                &nbsp;
                {intl.get(
                  JOB_SALARY_DEPOSIT_FREQUENCY_T_LABELS[salary.frequency]
                )}
              </Typography>
            ) : (
              false
            )}
          </Stack>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            rowGap={1}
            my={1}>
            <Grid item xs={12} sm="auto">
              <Stack>
                {companyWebsite ? (
                  <Typography>
                    <Typography
                      variant="subtitle5"
                      color={colorPalette.black.hover.base}>
                      {intl.get("t_general_website")}:
                    </Typography>
                    &nbsp;
                    <MuiLink
                      variant="body2"
                      href={companyWebsite}
                      target="_blank"
                      color={colorPalette.lightBlue.base}
                      rel="noopener noreferrer">
                      {companyWebsite}
                    </MuiLink>
                  </Typography>
                ) : (
                  false
                )}
                {agencyName ? (
                  <Typography color={colorPalette.black.hover.base}>
                    {intl.get("t_job_posted_by", { agencyName })}
                  </Typography>
                ) : (
                  false
                )}
              </Stack>
            </Grid>
            {mode === "search_result" ? (
              <Grid item xs={12} sm="auto" ml="auto">
                {isApplied ? (
                  <Button
                    data-testid="job_detail_card_apply_button"
                    size="large"
                    fullWidth
                    disabled>
                    {intl.get("t_general_applied")}
                  </Button>
                ) : handleApply ? (
                  jobApplyStatusCode === JOB_APPLY_STATUS_CODE.CAN_APPLY ? (
                    <ApplyBtnWithDialog />
                  ) : (
                    <Tooltip
                      PopperProps={{
                        sx: {
                          px: 4
                        }
                      }}
                      enterTouchDelay={0}
                      title={
                        <ApplyButtonTooltipTitle
                          userProfileStatus={userProfileStatus}
                          jobStatus={{ isActive: true }}
                        />
                      }
                      arrow>
                      <Box sx={{ cursor: "pointer" }}>
                        <Button
                          data-testid="job_detail_card_apply_button"
                          disabled>
                          {" "}
                          {intl.get("t_general_apply")}
                        </Button>
                      </Box>
                    </Tooltip>
                  )
                ) : (
                  <Button
                    data-testid="job_detail_card_apply_button"
                    fullWidth
                    disabled>
                    {intl.get("t_general_apply")}
                  </Button>
                )}
              </Grid>
            ) : (
              false
            )}
          </Grid>
        </Stack>
      </StyledPaperHeader>
      <StyledPaperBody>
        <Typography variant="subtitle2" mt={1}>
          {intl.get("t_job_details_job_overview")}
        </Typography>
        <Grid container spacing={2} my={0} alignItems="center">
          <Grid item xs={12} sm={4} md={4} lg={3}>
            <Stack direction="row" gap={1} alignItems="center">
              <JobDescriptionIcon filled icon="edit_document" />
              <Stack>
                <Typography variant="subtitle4">
                  {intl.get("t_job_visa_sponsorship")}
                </Typography>
                <Typography>
                  {visaSponsorship &&
                  JOB_VISA_SPONSORSHIP_AVAILABLE_T_LABELS[visaSponsorship]
                    ? intl.get(
                        JOB_VISA_SPONSORSHIP_AVAILABLE_T_LABELS[visaSponsorship]
                      )
                    : "-"}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={3}>
            <Stack direction="row" gap={1} alignItems="center">
              <JobDescriptionIcon filled icon="currency_yen" />
              <Stack>
                <Typography variant="subtitle4">
                  {intl.get("t_general_salary")}
                </Typography>
                {salary ? (
                  <Typography>
                    {salary.min ? (
                      <>
                        {roundSalary(salary.min)}
                        &nbsp;-
                      </>
                    ) : (
                      false
                    )}
                    &nbsp;
                    {roundSalary(salary.max)}
                    &nbsp;
                    {intl.get(
                      JOB_SALARY_DEPOSIT_FREQUENCY_T_LABELS[salary.frequency]
                    )}
                  </Typography>
                ) : (
                  <Typography>-</Typography>
                )}
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={3}>
            <Stack direction="row" gap={1} alignItems="center">
              <JobDescriptionIcon filled icon="psychology" />
              <Stack>
                <Typography variant="subtitle4">
                  {intl.get("t_job_experience")}
                </Typography>
                <Typography>
                  {experienceRequirement &&
                  YEARS_OF_EXPERIENCE_T_LABELS[experienceRequirement]
                    ? intl.get(
                        YEARS_OF_EXPERIENCE_T_LABELS[experienceRequirement]
                      )
                    : "-"}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={3}>
            <Stack direction="row" gap={1} alignItems="center">
              <JobDescriptionIcon filled icon="schedule" />
              <Stack>
                <Typography variant="subtitle4">
                  {intl.get("t_resumes_job_detail_form_label_contract_type")}
                </Typography>
                <Typography>
                  {jobContractType &&
                  JOB_CONTRACT_TYPE_T_LABELS[jobContractType]
                    ? intl.get(JOB_CONTRACT_TYPE_T_LABELS[jobContractType])
                    : "-"}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={3}>
            <Stack direction="row" gap={1} alignItems="center">
              <JobDescriptionIcon filled icon="laptop_mac" />
              <Stack>
                <Typography variant="subtitle4">
                  {intl.get("t_job_remote_work")}
                </Typography>
                {remoteAllowed &&
                JOB_REMOTE_WORK_TYPE_T_LABELS[remoteAllowed] ? (
                  <Typography>
                    {intl.get(JOB_REMOTE_WORK_TYPE_T_LABELS[remoteAllowed])}
                  </Typography>
                ) : (
                  "-"
                )}
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={3}>
            <Stack direction="row" gap={1} alignItems="center">
              <JobDescriptionIcon icon="forum" />
              <Stack>
                <Typography variant="subtitle4">
                  {intl.get("t_general_japanese_level")}
                </Typography>
                <Typography>
                  {jaProficiency && LANGUAGE_PROFICIENCY_T_LABELS[jaProficiency]
                    ? intl.get(LANGUAGE_PROFICIENCY_T_LABELS[jaProficiency])
                    : "-"}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={3}>
            <Stack direction="row" gap={1} alignItems="center">
              <JobDescriptionIcon icon="record_voice_over" />
              <Stack>
                <Typography variant="subtitle4">
                  {intl.get("t_general_english_level")}
                </Typography>
                <Typography>
                  {enProficiency && LANGUAGE_PROFICIENCY_T_LABELS[enProficiency]
                    ? intl.get(LANGUAGE_PROFICIENCY_T_LABELS[enProficiency])
                    : "-"}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
        <Box my={3}>
          <Divider />
        </Box>
        {jobDescriptionPlainText ? (
          <>
            <Typography variant="subtitle2" mt={2}>
              {intl.get("t_job_details_job_description")}
            </Typography>
            <Box>
              <WYSIWYG
                mode="view"
                defaultValue={
                  jobDescription ??
                  convertToRaw(ContentState.createFromText(""))
                }
              />
            </Box>
          </>
        ) : (
          false
        )}
        {mustRequirementsPlainText ? (
          <>
            <Typography variant="subtitle2" mt={2}>
              {intl.get("t_job_post_job_information_step_must_requirement")}
            </Typography>
            <Box>
              <WYSIWYG
                mode="view"
                defaultValue={
                  mustRequirements ??
                  convertToRaw(ContentState.createFromText(""))
                }
              />
            </Box>
          </>
        ) : (
          false
        )}
        {languageRequirement?.length > 0 ? (
          <>
            <Typography variant="subtitle2" my={2}>
              {intl.get(
                "t_job_post_job_confirmation_step_language_requirement"
              )}
            </Typography>
            {languageRequirement.map((singleLanguage, index) => {
              return (
                <>
                  {index === 2 ? (
                    <Typography variant="subtitle4" my={2}>
                      {intl.get(
                        "t_job_post_job_confirmation_step_other_languages"
                      )}
                    </Typography>
                  ) : (
                    false
                  )}
                  <StyledList key={index}>
                    <ListItem>
                      {getLanguageName(
                        singleLanguage?.name,
                        intl.getInitOptions()
                          .currentLocale as typeof LOCALE[keyof typeof LOCALE]
                      )}
                      :&nbsp;
                      {intl.get(
                        LANGUAGE_PROFICIENCY_T_LABELS[
                          singleLanguage?.proficiency as typeof LANGUAGE_PROFICIENCY[keyof typeof LANGUAGE_PROFICIENCY]
                        ]
                      )}
                    </ListItem>
                  </StyledList>
                </>
              );
            })}
          </>
        ) : (
          false
        )}

        {requiredSkills.length > 0 ? (
          <>
            <Typography variant="subtitle2" mt={2}>
              {intl.get("t_general_skills")}
            </Typography>
            <Stack direction="row" gap={2} flexWrap="wrap">
              {isSkillsLoading ? (
                <SkeletonKeyword multiple />
              ) : (
                <>
                  {skills.map((singleRequiredSkill, idx) => {
                    return (
                      <MuiLink
                        underline="none"
                        component={Link}
                        target="_blank"
                        to={`/search?skills=${singleRequiredSkill.name}&hide_search=1`}
                        key={idx}>
                        <Keyword
                          label={`${singleRequiredSkill.name} - ${intl.get(
                            YEARS_OF_EXPERIENCE_T_LABELS[
                              singleRequiredSkill.years_of_experience
                            ]
                          )}`}
                          isClickable
                        />
                      </MuiLink>
                    );
                  })}
                </>
              )}
            </Stack>
          </>
        ) : (
          false
        )}
        {otherDetailsPlainText ? (
          <>
            <Typography variant="subtitle2" mt={2}>
              {intl.get("t_job_details_other_details")}
            </Typography>
            <WYSIWYG
              mode="view"
              defaultValue={
                otherDetails ?? convertToRaw(ContentState.createFromText(""))
              }
            />
          </>
        ) : (
          false
        )}
        {additionInfoFileDetails?.name ? (
          <>
            <Typography variant="subtitle2" mt={2}>
              {intl.get("t_job_post_job_confirmation_step_more_information")}
            </Typography>
            <br />
            <Typography variant="body1" mb={1} mt={2}>
              <Stack
                component="span"
                direction="row"
                alignItems="center"
                gap={1}>
                <MuiLink underline="none" href={downloadFileUrl}>
                  <StyledDownloadButton
                    variant="text"
                    startAdornment={<Icon type="download" />}>
                    {additionInfoFileDetails?.name}
                  </StyledDownloadButton>
                </MuiLink>
              </Stack>
            </Typography>
            <Stack component="span" direction="row" alignItems="center" mb={1}>
              <Typography
                variant="subtitle5"
                color={theme.palette.text.primary}>
                {intl.get(
                  "t_job_post_job_additional_information_step_job_description_updated_at"
                )}
                :&nbsp;
              </Typography>
              {mode === "employer_preview" ? (
                <Typography variant="body2" color={theme.palette.text.primary}>
                  {dayjs(additionInfoFileDetails?.uploaded_at?.toDate())
                    .locale(translate.getCurrentLocaleShort())
                    .fromNow()}
                </Typography>
              ) : (
                false
              )}
            </Stack>
          </>
        ) : (
          false
        )}
        {cvFileRequirements?.is_en_cv_mandatory ||
        cvFileRequirements?.is_ja_cv_mandatory ||
        cvFileRequirements?.is_ja_rirekisho_mandatory ? (
          <>
            <Typography variant="subtitle2" my={2}>
              {intl.get(
                "t_job_post_job_additional_information_step_required_documents"
              )}
            </Typography>
            <br />
            {cvFileRequirements.is_en_cv_mandatory ? (
              <StyledList>
                <ListItem>{intl.get("t_general_english_cv")}</ListItem>
              </StyledList>
            ) : (
              false
            )}
            {cvFileRequirements.is_ja_cv_mandatory ? (
              <StyledList>
                <ListItem>{intl.get("t_general_japanese_cv")}</ListItem>
              </StyledList>
            ) : (
              false
            )}
            {cvFileRequirements.is_ja_rirekisho_mandatory ? (
              <StyledList>
                <ListItem>{intl.get("t_general_japanese_rirekisho")}</ListItem>
              </StyledList>
            ) : (
              false
            )}
          </>
        ) : (
          false
        )}

        {hiringFlow ? (
          <>
            <Typography variant="subtitle2" my={2}>
              {intl.get("t_selection_process_title")}
            </Typography>
            <br />
            <StyledNumberList>
              {hiringFlow.resume_screening?.steps?.map((singleStep) => (
                <ListItem key={singleStep.key}>
                  {intl.get(
                    JOB_HIRING_FLOW_RESUME_SCREENING_T_LABELS[
                      singleStep.type as typeof JOB_HIRING_FLOW_RESUME_SCREENING[keyof typeof JOB_HIRING_FLOW_RESUME_SCREENING]
                    ]
                  )}
                </ListItem>
              ))}
              {hiringFlow.interview?.steps.map((singleStep) => (
                <ListItem key={singleStep.key}>
                  {intl.get(
                    JOB_HIRING_FLOW_INTERVIEW_T_LABELS[
                      singleStep.type as typeof JOB_HIRING_FLOW_INTERVIEW[keyof typeof JOB_HIRING_FLOW_INTERVIEW]
                    ]
                  )}
                </ListItem>
              ))}
              {hiringFlow.offer?.steps?.[0] ? (
                <ListItem key={hiringFlow.offer?.steps?.[0].key}>
                  {intl.get(
                    JOB_HIRING_FLOW_OFFER_T_LABELS[
                      hiringFlow.offer?.steps?.[0]
                        .type as typeof JOB_HIRING_FLOW_OFFER[keyof typeof JOB_HIRING_FLOW_OFFER]
                    ]
                  )}
                </ListItem>
              ) : (
                false
              )}
            </StyledNumberList>
          </>
        ) : (
          false
        )}

        {mode === "search_result" ? (
          <Grid container>
            <Grid item xs={12} sm="auto" ml="auto">
              {isApplied ? (
                <Button
                  data-testid="job_detail_card_apply_button"
                  size="large"
                  fullWidth
                  disabled>
                  {intl.get("t_general_applied")}
                </Button>
              ) : handleApply ? (
                jobApplyStatusCode === JOB_APPLY_STATUS_CODE.CAN_APPLY ? (
                  <ApplyBtnWithDialog />
                ) : (
                  <Tooltip
                    PopperProps={{
                      sx: {
                        px: 4
                      }
                    }}
                    enterTouchDelay={0}
                    title={
                      <ApplyButtonTooltipTitle
                        userProfileStatus={userProfileStatus}
                        jobStatus={{ isActive: true }}
                      />
                    }
                    arrow>
                    <Box sx={{ cursor: "pointer" }}>
                      <Button
                        data-testid="job_detail_card_apply_button"
                        disabled>
                        {intl.get("t_general_apply")}
                      </Button>
                    </Box>
                  </Tooltip>
                )
              ) : (
                <Button
                  fullWidth
                  disabled
                  data-testid="job_detail_card_apply_button">
                  {intl.get("t_general_apply")}
                </Button>
              )}
            </Grid>
          </Grid>
        ) : (
          false
        )}
      </StyledPaperBody>
    </Paper>
  );
};

export default JobDetailCard;
