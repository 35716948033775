import { ChangeEvent, MouseEvent, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import {
  InfoOutlined as InfoOutlinedIcon,
  NavigateNext as NavigateNextIcon
} from "@mui/icons-material";
import {
  Box,
  Link,
  List,
  ListItem,
  Stack,
  styled,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip
} from "@mui/material";
import dayjs from "dayjs";
import relative from "dayjs/plugin/relativeTime";
import {
  collection,
  collectionGroup,
  doc,
  DocumentData,
  endBefore,
  getCountFromServer,
  getDoc,
  getDocs,
  limit,
  limitToLast,
  orderBy,
  Query,
  query,
  QueryEndAtConstraint,
  QueryFieldFilterConstraint,
  QueryLimitConstraint,
  QueryOrderByConstraint,
  QueryStartAtConstraint,
  startAfter,
  where
} from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { useHttpsCallable } from "react-firebase-hooks/functions";

import SkeletonAvatar from "@skeletons/SkeletonAvatar";
import SkeletonJobInvitationDataTable from "@skeletons/SkeletonJobInvitationDataTable";
import SkeletonStatus from "@skeletons/SkeletonStatus";
import SkeletonTypography from "@skeletons/SkeletonTypography";

import Avatar from "@components/Avatar";
import Button from "@components/Button";
import InvitedResumeDrawer from "@components/CandidateResumeDrawer/InvitedResumeDrawer";
import DataTableWrapper from "@components/DataTable/DataTableWrapper";
import Drawer from "@components/Drawer";
import Status from "@components/Status";
import Typography from "@components/Typography";

import useCompanyDetails from "@hooks/database/useCompanyDetails";
import useUserProfile from "@hooks/database/useUserProfile";
import useToast from "@hooks/useToast";

import ApplicationID from "@interfaces/database/ApplicationID";
import JobApplicationInvitation from "@interfaces/database/JobApplicationInvitation";
import JobID from "@interfaces/database/JobID";
import JobProfile from "@interfaces/database/JobProfile";
import MultiLingual from "@interfaces/database/MultiLingual";
import UserProfile from "@interfaces/database/UserProfile";
import CandidateDetails from "@interfaces/functions/CandidateDetails";

import EnvironmentSpecific from "@utils/components/EnvironmentSpecific";
import {
  ENVIRONMENT,
  FIRESTORE_COLLECTIONS,
  JOB_APPLICATION_INVITATION_ACTION_TYPE,
  JOB_APPLICATION_INVITATION_ACTION_TYPE_T_LABELS,
  JOB_POSTING_STATUS,
  LOCALE_SHORT,
  PAGINATION
} from "@utils/config";
import { auth, db, functions } from "@utils/firebase";
import { resolveMultiLingual } from "@utils/multiLingual";
import translate, { intl } from "@utils/translate";

dayjs.extend(relative);

const StyledList = styled(List)({
  "listStyleType": "disc",
  "padding": "0.25rem 1.25rem",
  "& .MuiListItem-root": {
    padding: 0,
    display: "list-item"
  }
});

interface InvitedCandidatesDataTableProps {
  filterJobId?: JobID;
}

interface Application extends JobApplicationInvitation {
  applicationId: ApplicationID;
  job?: JobProfile;
  candidate?: UserProfile;
}

interface Column {
  id: "candidate" | "jobTitle" | "stage" | "activityDate";
  label: string;
  align?: "left" | "right" | "center";
  enableSort?: boolean;
}

interface Row {
  applicationId: ApplicationID;
  jobId: JobID;
  candidate: {
    id: string;
    fullName: string;
    initials: string;
    photoUrl: string;
    jobTitle?: MultiLingual<string>;
  };
  jobTitle?: MultiLingual<string>;
  stage: typeof JOB_APPLICATION_INVITATION_ACTION_TYPE[keyof typeof JOB_APPLICATION_INVITATION_ACTION_TYPE];
  activityDate?: Date;
  isResumePurchased?: boolean;
  isJobActive: boolean;
  invitedDeclinedReasons?: Array<MultiLingual<string>>;
}

type FirestoreQueryParams = [
  Query<unknown>,
  ...Array<
    | QueryOrderByConstraint
    | QueryFieldFilterConstraint
    | QueryStartAtConstraint
    | QueryEndAtConstraint
    | QueryLimitConstraint
  >
];

const COLUMNS: ReadonlyArray<Column> = [
  {
    id: "candidate",
    label: "t_general_candidate",
    align: "left",
    enableSort: false
  },
  {
    id: "jobTitle",
    label: "t_general_job_title",
    enableSort: false
  },
  {
    id: "stage",
    label: "t_general_stage",
    enableSort: true
  },
  {
    id: "activityDate",
    label: "t_general_invitation_date",
    enableSort: true
  }
];

const StyledTablePagination = styled(TablePagination)({
  "& .MuiTablePagination-select, & .MuiInputBase-root": {
    width: "max-content"
  }
});

const InvitedCandidatesDataTable = ({
  filterJobId = ""
}: InvitedCandidatesDataTableProps) => {
  const [user] = useAuthState(auth);
  const companyData = useCompanyDetails();
  const navigate = useNavigate();
  const toast = useToast();
  const userProfile = useUserProfile();
  const [getCandidateDetails] = useHttpsCallable(
    functions,
    "getCandidateDetails"
  );

  // Company variables
  const companyId = userProfile.value?.company_id ?? "";

  const jobTitleOptions =
    resolveMultiLingual(companyData.value?.job_titles) ?? {};

  // Table data variables
  const [tableData, setTableData] = useState<Record<string, Row | undefined>>(
    {}
  );
  const [loading, setLoading] = useState<boolean>(true);

  // Drawer variables
  const [openCandidateProfileDrawerIndex, setOpenCandidateProfileDrawerIndex] =
    useState<string>("");

  // Sorting and Pagination variables
  const [totalApplications, setTotalApplications] = useState<
    number | undefined
  >(undefined);
  const [totalApplicationsLoading, setTotalApplicationsLoading] =
    useState<boolean>(true);

  const [sortedBy, setSortedBy] = useState<Column["id"]>("activityDate");
  const [orderDirection, setOrderDirection] = useState<
    "asc" | "desc" | undefined
  >("desc");

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    PAGINATION.TABLE_ROWS_PER_PAGE[0]
  );
  const [pageDirection, setPageDirection] = useState<"none" | "prev" | "next">(
    "none"
  );
  const [lastJob, setLastJob] = useState<DocumentData>();
  const [firstJob, setFirstJob] = useState<DocumentData>();

  const applicationStatus = [
    JOB_APPLICATION_INVITATION_ACTION_TYPE.INVITED,
    JOB_APPLICATION_INVITATION_ACTION_TYPE.INVITED_DECLINED
  ];

  // Fetching application count
  const getApplicationCount = async () => {
    try {
      setTotalApplicationsLoading(true);
      const applicationsRef = collectionGroup(
        db,
        FIRESTORE_COLLECTIONS.APPLICATIONS
      );
      const applicationQuery = query(
        applicationsRef,
        where("company_id", "==", companyId),
        where("metadata.status", "in", applicationStatus)
      );
      const querySnapshot = await getCountFromServer(applicationQuery);
      setTotalApplications(querySnapshot.data().count);
      setTotalApplicationsLoading(false);
    } catch (error) {
      toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
    }
  };

  useEffect(() => {
    if (companyId) {
      getApplicationCount();
    }
  }, [companyId]);

  useEffect(() => {
    const styleTagId = "body-drawer-style";
    const styleTag = document.getElementById(styleTagId);

    if (openCandidateProfileDrawerIndex) {
      if (!styleTag) {
        document.head.insertAdjacentHTML(
          "beforeend",
          `<style id="${styleTagId}">body{overflow:hidden!important}</style>`
        );
      }
    } else if (styleTag) {
      styleTag.remove();
    }

    return () => styleTag?.remove();
  }, [openCandidateProfileDrawerIndex]);

  const getOrderBy = (sortedBy: string) => {
    const orderClauses = [];
    switch (sortedBy) {
      case "jobTitle":
        {
          const titleEnOrderBy = orderBy(
            "metadata.job_title.en",
            orderDirection
          );
          const titleJaOrderBy = orderBy(
            "metadata.job_title.ja",
            orderDirection
          );
          if (translate.getCurrentLocaleShort() === LOCALE_SHORT.EN) {
            orderClauses.push(titleEnOrderBy);
            orderClauses.push(titleJaOrderBy);
          } else {
            orderClauses.push(titleJaOrderBy);
            orderClauses.push(titleEnOrderBy);
          }
        }
        break;
      case "stage":
        orderClauses.push(orderBy("metadata.status", orderDirection));
        break;
      case "activityDate":
      default:
        orderClauses.push(orderBy("metadata.updated_at", orderDirection));
        break;
    }

    return orderClauses;
  };

  const getWhereBy = (searchedJobId = "") => {
    if (searchedJobId) {
      return where("job_id", "==", searchedJobId);
    } else {
      // Here, companyId is global, so not passed through parameter.
      return where("company_id", "==", companyId);
    }
  };

  // Sorting and Pagination
  const handleSortRequest = (sortBy: Column["id"]) => {
    // No point in performing sort when table is not available / loaded.
    if (loading || !totalApplications) {
      return;
    }

    // Some fields are non sortable.
    if (sortBy == "candidate" || sortBy == "jobTitle") {
      return;
    }

    setSortedBy(sortBy);
    setOrderDirection(orderDirection === "asc" ? "desc" : "asc");
    // reset pagination
    setLastJob(undefined);
    setFirstJob(undefined);
    setPage(0);
    setPageDirection("none");
  };

  const handleChangePage = (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (page < newPage) {
      setPageDirection("next");
    } else {
      setPageDirection("prev");
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    // reset pagination
    setLastJob(undefined);
    setFirstJob(undefined);
    setPage(0);
    setPageDirection("none");
  };

  // Fetching applications
  const fetchApplications = async () => {
    try {
      if (companyId && totalApplications && totalApplications > 0) {
        setLoading(true);

        const jobRef = collection(db, FIRESTORE_COLLECTIONS.JOBS);
        const applicationsRef = collectionGroup(
          db,
          FIRESTORE_COLLECTIONS.APPLICATIONS
        );

        const applications: Array<Application> = [];
        const queryParams: FirestoreQueryParams = [applicationsRef];
        queryParams.push(getWhereBy(filterJobId));
        queryParams.push(where("metadata.status", "in", applicationStatus));
        queryParams.push(...getOrderBy(sortedBy));

        switch (pageDirection) {
          case "next":
            {
              if (lastJob) {
                queryParams.push(startAfter(lastJob));
              }
              queryParams.push(limit(rowsPerPage));
            }
            break;
          case "prev":
            {
              if (firstJob) {
                queryParams.push(endBefore(firstJob));
              }
              queryParams.push(limitToLast(rowsPerPage));
            }
            break;
          case "none":
          default:
            queryParams.push(limit(rowsPerPage));
            break;
        }

        const applicationQuery = query(...queryParams);

        const querySnapshot = await getDocs(applicationQuery);
        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            applications.push({
              applicationId: doc.id,
              ...(doc.data() as JobApplicationInvitation)
            });
          });

          setFirstJob(querySnapshot.docs[0]);
          setLastJob(querySnapshot.docs[querySnapshot.docs.length - 1]);

          const emptyTableData: Record<string, undefined> = {};
          for (const singleApplication of applications) {
            emptyTableData[singleApplication.applicationId] = undefined;
          }

          setTableData(emptyTableData);

          for (const singleApplication of applications) {
            (async (applicationData: Application) => {
              // Fetch job title from Job Id
              const jobDocRef = doc(jobRef, applicationData.job_id);
              const jobDocSnapshot = await getDoc(jobDocRef);

              if (jobDocSnapshot.exists()) {
                const jobData = jobDocSnapshot.data();
                applicationData.job = jobData as JobProfile;
              }

              const lastActionIndex = applicationData.actions.length - 1 ?? 0;

              let singleCandidateDetail = undefined;

              try {
                const result = await getCandidateDetails({
                  companyId,
                  candidateIds: [applicationData.candidate_id]
                });
                const candidateDetails = result?.data as Record<
                  string,
                  CandidateDetails
                >;
                singleCandidateDetail =
                  candidateDetails[applicationData.candidate_id];
              } catch (e) {
                /* no-op */
              }

              // FIXME: Why is jobTitle not required to resolve here, but name / initials needs to be done.
              const tableRow: Row = {
                applicationId: applicationData.applicationId,
                jobId: applicationData.job_id,
                stage:
                  applicationData.actions[lastActionIndex].action_type ===
                  JOB_APPLICATION_INVITATION_ACTION_TYPE.RESUME_PURCHASED
                    ? applicationData.actions[lastActionIndex - 1].action_type
                    : applicationData.actions[lastActionIndex].action_type,
                activityDate:
                  applicationData.actions[lastActionIndex].updated_at.toDate(),
                jobTitle: applicationData.job?.job_title,
                candidate: {
                  id: applicationData.candidate_id,
                  fullName:
                    resolveMultiLingual(singleCandidateDetail?.name) ?? "",
                  initials:
                    resolveMultiLingual(singleCandidateDetail?.initials) ?? "",
                  photoUrl: singleCandidateDetail?.photo_url ?? "",
                  jobTitle: singleCandidateDetail?.current_job_title
                },
                isResumePurchased:
                  !singleCandidateDetail?.is_masked_info ?? false,
                isJobActive:
                  applicationData.job?.status || applicationData.job?.statusV2
                    ? applicationData.job?.status ==
                        JOB_POSTING_STATUS.OK_AUTO_REVIEWED ||
                      applicationData.job?.status ==
                        JOB_POSTING_STATUS.OK_MANUAL_REVIEWED ||
                      applicationData.job?.statusV2.en ==
                        JOB_POSTING_STATUS.OK_AUTO_REVIEWED ||
                      applicationData.job?.statusV2.en ==
                        JOB_POSTING_STATUS.OK_MANUAL_REVIEWED ||
                      applicationData.job?.statusV2.ja ==
                        JOB_POSTING_STATUS.OK_AUTO_REVIEWED ||
                      applicationData.job?.statusV2.ja ==
                        JOB_POSTING_STATUS.OK_MANUAL_REVIEWED
                    : false,
                invitedDeclinedReasons:
                  JOB_APPLICATION_INVITATION_ACTION_TYPE.INVITED_DECLINED ===
                  applicationData.actions[lastActionIndex].action_type
                    ? applicationData.actions[lastActionIndex].comments
                    : []
              };

              setTableData((prevData) => ({
                ...prevData,
                [applicationData.applicationId]: tableRow
              }));
            })(singleApplication);
          }
        } else {
          setTableData({});
        }
        setLoading(false);
      } else if (totalApplications == 0) {
        setLoading(false);
        setTableData({});
      } else {
        setTableData({});
      }
    } catch (error) {
      toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
    }
  };

  useEffect(() => {
    fetchApplications();
  }, [
    rowsPerPage,
    page,
    orderDirection,
    sortedBy,
    companyId,
    totalApplications
  ]);

  useEffect(() => {
    // Reset pagination, firstJob, and lastJob when searchedJob changes
    setPage(0);
    setPageDirection("none");
    setFirstJob(undefined);
    setLastJob(undefined);

    // Fetch applications again with the updated searchedJob
    fetchApplications();
  }, [filterJobId]);

  const handleError = () => {
    setOpenCandidateProfileDrawerIndex("");
    toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
  };

  return loading || totalApplicationsLoading ? (
    <SkeletonJobInvitationDataTable />
  ) : totalApplications == 0 && Object.keys(jobTitleOptions).length === 0 ? (
    // No job posted yet
    <Stack spacing={3} height={400} justifyContent="center" alignItems="center">
      <Typography variant="h3">
        {intl.get("t_employer_dashboard_job_posted_table_no_posted_jobs_yet")}
      </Typography>
      {user?.emailVerified ? (
        <Button
          size="medium"
          endAdornment={<NavigateNextIcon />}
          handleClick={() =>
            navigate(
              `/${translate.getCurrentLocale()}/employers/jobs/new/${translate.getCurrentLocaleShort()}/company-information`
            )
          }>
          {intl.get("t_employer_dashboard_post_job_button")}
        </Button>
      ) : (
        <Tooltip
          arrow
          placement="bottom"
          enterTouchDelay={0}
          title={intl.get("t_employer_dashboard_post_job_disabled_tooltip")}>
          <span>
            <Button disabled size="medium" endAdornment={<NavigateNextIcon />}>
              {intl.get("t_employer_dashboard_post_job_button")}
            </Button>
          </span>
        </Tooltip>
      )}
    </Stack>
  ) : Object.keys(tableData).length === 0 ? (
    // Search result not found
    filterJobId ? (
      <Stack
        spacing={3}
        height={400}
        justifyContent="center"
        alignItems="center">
        <Typography variant="h3">
          {intl.get(
            "t_employer_dashboard_job_invitations_table_no_search_result_title"
          )}
        </Typography>
        <Typography variant="h6">
          {intl.get(
            "t_employer_dashboard_job_invitations_table_no_search_result_subtitle"
          )}
        </Typography>
      </Stack>
    ) : (
      // No invites yet
      <Stack
        spacing={3}
        height={400}
        justifyContent="center"
        alignItems="center">
        <Typography variant="h3">
          {intl.get(
            "t_employer_dashboard_job_applications_table_no_invites_yet"
          )}
        </Typography>
        <Button
          size="medium"
          endAdornment={<NavigateNextIcon />}
          handleClick={() =>
            navigate(`/${translate.getCurrentLocale()}/employers/search`)
          }>
          {intl.get("t_general_invite_candidates")}
        </Button>
      </Stack>
    )
  ) : (
    <>
      {/* Invites table with data */}
      <DataTableWrapper>
        <TableHead>
          <TableRow>
            {COLUMNS?.map((singleColumn) => (
              <TableCell
                key={singleColumn.id}
                width="16.66%"
                align={singleColumn?.align ?? "center"}>
                {singleColumn.enableSort ? (
                  <TableSortLabel
                    sx={{
                      pl:
                        !singleColumn?.align || singleColumn?.align == "center"
                          ? 3
                          : 0
                    }}
                    onClick={() => handleSortRequest(singleColumn.id)}
                    active={sortedBy === singleColumn.id}
                    direction={orderDirection}>
                    <Typography variant="subtitle5">
                      {intl.get(singleColumn.label)}
                    </Typography>
                  </TableSortLabel>
                ) : (
                  <Typography variant="subtitle5">
                    {intl.get(singleColumn.label)}
                  </Typography>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {Object.keys(tableData).map((applicationId) => {
          const singleData = tableData[applicationId];
          if (!singleData) {
            return (
              <TableRow key={applicationId}>
                <TableCell align="left">
                  <Stack
                    direction="row"
                    alignItems="center"
                    gap={1}
                    width="100%">
                    <SkeletonAvatar size="medium" />
                    <Stack width="calc(100% - 2.5rem)">
                      <SkeletonTypography variant="subtitle5" />
                      <SkeletonTypography variant="body2" />
                    </Stack>
                  </Stack>
                </TableCell>
                <TableCell align="center">
                  <SkeletonTypography variant="body2" width="short" />
                </TableCell>
                <TableCell align="center">
                  <Stack alignItems="center">
                    <SkeletonStatus />
                  </Stack>
                </TableCell>
                <TableCell align="center">
                  <SkeletonTypography variant="body2" width="short" />
                </TableCell>
              </TableRow>
            );
          }
          return (
            <Drawer
              key={applicationId}
              initiatorStyle={{
                display: "table-row-group"
              }}
              initiatorComponent="tbody"
              initiator={
                <TableRow
                  className="hoverable-row"
                  sx={{ cursor: "pointer !important" }}>
                  <TableCell>
                    <Stack direction="row" alignItems="center" gap={1}>
                      <Avatar
                        allowAddPhoto={false}
                        imgSrc={singleData.candidate.photoUrl}
                        fullName={singleData.candidate.fullName}
                        initials={singleData.candidate.initials}
                        size="medium"
                      />
                      <Stack>
                        {singleData?.isResumePurchased ? (
                          <Typography variant="subtitle5">
                            {singleData.candidate.fullName}
                          </Typography>
                        ) : (
                          <Typography
                            variant="subtitle5"
                            sx={{
                              filter: "blur(4px)",
                              userSelect: "none"
                            }}>
                            {intl.get(
                              "t_employer_resume_purchase_table_candidate_name"
                            )}
                          </Typography>
                        )}
                        <Typography variant="body2">
                          {resolveMultiLingual(singleData.candidate.jobTitle) ??
                            intl.get("t_general_fresher")}
                        </Typography>
                        <EnvironmentSpecific env={ENVIRONMENT.STAGE}>
                          <Typography
                            variant="helperText"
                            component="p"
                            display="block">
                            {singleData.candidate.id}
                          </Typography>
                        </EnvironmentSpecific>
                      </Stack>
                    </Stack>
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip
                      title={
                        !singleData.isJobActive
                          ? intl.get(
                              "t_employer_dashboard_application_job_not_in_search"
                            )
                          : ""
                      }>
                      <>
                        <Link
                          href={`/${translate.getCurrentLocale()}/jobs/${
                            singleData.jobId
                          }`}
                          target="_blank"
                          variant="body2"
                          onClick={(event) => {
                            event.stopPropagation();
                          }}
                          color="inherit"
                          underline="none"
                          rel="noopener noreferrer"
                          sx={{
                            "cursor": "pointer",
                            "&:hover": { textDecoration: "underline" }
                          }}
                          display="block">
                          {resolveMultiLingual(singleData.jobTitle)}
                        </Link>
                        <EnvironmentSpecific env={ENVIRONMENT.STAGE}>
                          <Link
                            href={`/${translate.getCurrentLocale()}/jobs/${
                              singleData.jobId
                            }`}
                            target="_blank"
                            variant="helperText"
                            onClick={(event) => {
                              event.stopPropagation();
                            }}
                            color="inherit"
                            underline="none"
                            rel="noopener noreferrer"
                            sx={{
                              "cursor": "pointer",
                              "&:hover": { textDecoration: "underline" }
                            }}
                            display="block">
                            {singleData.jobId}
                          </Link>
                        </EnvironmentSpecific>
                      </>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    <Stack
                      width="100%"
                      justifyContent="center"
                      alignItems="center">
                      <Stack
                        width="max-content"
                        position="relative"
                        direction="row"
                        alignItems="center"
                        columnGap={1}
                        justifyContent="center">
                        <Status label={singleData.stage} />
                        {singleData.stage ===
                          JOB_APPLICATION_INVITATION_ACTION_TYPE.INVITED_DECLINED &&
                        singleData.invitedDeclinedReasons &&
                        singleData.invitedDeclinedReasons.length > 0 ? (
                          <Stack
                            right={-28}
                            justifyContent="center"
                            alignItems="center"
                            position="absolute">
                            <Tooltip
                              enterTouchDelay={0}
                              arrow
                              title={
                                <Box p={1}>
                                  <Typography mb={0.5} variant="body1">
                                    {`${intl.get(
                                      JOB_APPLICATION_INVITATION_ACTION_TYPE_T_LABELS[
                                        singleData.stage
                                      ]
                                    )} ${intl.get("t_general_reasons")}:`}
                                  </Typography>
                                  <StyledList>
                                    <Stack rowGap={0.5}>
                                      {singleData?.invitedDeclinedReasons?.map(
                                        (
                                          singleInvitedDeclinedReason: MultiLingual<string>,
                                          index: number
                                        ) => {
                                          const invitedDeclinedReason: string =
                                            resolveMultiLingual(
                                              singleInvitedDeclinedReason
                                            ) ?? "";
                                          return (
                                            <>
                                              {invitedDeclinedReason ? (
                                                <ListItem key={index}>
                                                  {invitedDeclinedReason}
                                                </ListItem>
                                              ) : (
                                                false
                                              )}
                                            </>
                                          );
                                        }
                                      )}
                                    </Stack>
                                  </StyledList>
                                </Box>
                              }>
                              <InfoOutlinedIcon />
                            </Tooltip>
                          </Stack>
                        ) : (
                          false
                        )}
                      </Stack>
                    </Stack>

                    <EnvironmentSpecific env={ENVIRONMENT.STAGE}>
                      <Typography
                        variant="helperText"
                        component="p"
                        display="block">
                        {singleData.applicationId}
                      </Typography>
                    </EnvironmentSpecific>
                  </TableCell>
                  <TableCell align="center">
                    {dayjs(singleData?.activityDate)
                      .locale(translate.getCurrentLocaleShort())
                      .fromNow()}
                  </TableCell>
                </TableRow>
              }
              drawerContent={
                <InvitedResumeDrawer
                  candidateId={singleData.candidate?.id}
                  candidateProfilePicUrl={singleData.candidate?.photoUrl}
                  handleError={handleError}
                />
              }
              isDrawerOpen={openCandidateProfileDrawerIndex === applicationId}
              setIsDrawerOpen={(drawerOpenStatus) => {
                if (drawerOpenStatus === true) {
                  setOpenCandidateProfileDrawerIndex(applicationId);
                } else {
                  setOpenCandidateProfileDrawerIndex("");
                }
              }}
            />
          );
        })}

        <TableFooter>
          <TableRow>
            <StyledTablePagination
              rowsPerPageOptions={PAGINATION.TABLE_ROWS_PER_PAGE}
              count={totalApplications ?? 0}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage={intl.get("t_general_table_pagination_label")}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </DataTableWrapper>
    </>
  );
};

export default InvitedCandidatesDataTable;
