import { ChangeEvent, MouseEvent, useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { Link as ReactRouterLink, useNavigate } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  ChatRounded as ChatRoundedIcon,
  CheckCircleRounded as CheckCircleRoundedIcon,
  InfoOutlined as InfoOutlinedIcon,
  NavigateNext as NavigateNextIcon
} from "@mui/icons-material";
import {
  Box,
  DialogActions,
  DialogContent,
  Link,
  List,
  ListItem,
  Stack,
  styled,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip
} from "@mui/material";
import dayjs from "dayjs";
import relative from "dayjs/plugin/relativeTime";
import {
  collection,
  collectionGroup,
  doc,
  DocumentData,
  endBefore,
  getCountFromServer,
  getDoc,
  getDocs,
  limit,
  limitToLast,
  orderBy,
  Query,
  query,
  QueryEndAtConstraint,
  QueryFieldFilterConstraint,
  QueryLimitConstraint,
  QueryOrderByConstraint,
  QueryStartAtConstraint,
  startAfter,
  where
} from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import * as yup from "yup";

import SkeletonAvatar from "@skeletons/SkeletonAvatar";
import SkeletonJobApplicationDataTable from "@skeletons/SkeletonJobApplicationDataTable";
import SkeletonStatus from "@skeletons/SkeletonStatus";
import SkeletonTypography from "@skeletons/SkeletonTypography";

import Avatar from "@components/Avatar";
import Button from "@components/Button";
import AppliedResumeDrawer from "@components/CandidateResumeDrawer/AppliedResumeDrawer";
import Checkbox from "@components/Checkbox";
import DataTableWrapper from "@components/DataTable/DataTableWrapper";
import Dialog from "@components/Dialog";
import Drawer from "@components/Drawer";
import Status from "@components/Status";
import Typography from "@components/Typography";

import useCompanyDetails from "@hooks/database/useCompanyDetails";
import useUserProfile from "@hooks/database/useUserProfile";
import { useOptions } from "@hooks/useOptions";
import useToast from "@hooks/useToast";

import ApplicationID from "@interfaces/database/ApplicationID";
import JobApplicationInvitation from "@interfaces/database/JobApplicationInvitation";
import JobID from "@interfaces/database/JobID";
import JobProfile from "@interfaces/database/JobProfile";
import MultiLingual from "@interfaces/database/MultiLingual";
import UserID from "@interfaces/database/UserID";
import UserProfile from "@interfaces/database/UserProfile";
import CandidateDetails from "@interfaces/functions/CandidateDetails";

import EnvironmentSpecific from "@utils/components/EnvironmentSpecific";
import {
  DIALOG_ACTION,
  EMPLOYER_DECLINED_CANDIDATE_REASONS,
  EMPLOYER_DECLINED_CANDIDATE_REASONS_T_LABELS,
  ENVIRONMENT,
  FIRESTORE_COLLECTIONS,
  FREE_TEXT_FIELD_MAX_LENGTH,
  JOB_APPLICATION_INVITATION_ACTION_TYPE,
  JOB_APPLICATION_INVITATION_ACTION_TYPE_T_LABELS,
  JOB_POSTING_STATUS,
  LOCALE_SHORT,
  PAGINATION
} from "@utils/config";
import { handleJobDecline } from "@utils/declineJob";
import { auth, db, functions } from "@utils/firebase";
import generateHiringProcessSteps from "@utils/generateHiringProcessSteps";
import { resolveMultiLingual } from "@utils/multiLingual";
import translate, { intl, intlEn, intlJa } from "@utils/translate";

dayjs.extend(relative);

interface ApplicantsDataTableProps {
  filterJobId?: JobID;
}

interface DeclineButtonProps {
  stage: typeof JOB_APPLICATION_INVITATION_ACTION_TYPE[keyof typeof JOB_APPLICATION_INVITATION_ACTION_TYPE];
  applicationId: ApplicationID;
  candidateId: UserID;
  jobId: JobID;
}

interface Application extends JobApplicationInvitation {
  applicationId: ApplicationID;
  job?: JobProfile;
  candidate?: UserProfile;
}

interface Column {
  id:
    | "candidate"
    | "jobTitle"
    | "stage"
    | "resumePurchaseStatus"
    | "activityDate";
  label: string;
  align?: "left" | "right" | "center";
  enableSort?: boolean;
}

export interface Row {
  applicationId: ApplicationID;
  hiringFlowActiveStepKey: string;
  jobId: JobID;
  jobData?: JobProfile;
  candidate: {
    id: string;
    fullName: string;
    initials: string;
    photoUrl: string;
    jobTitle?: MultiLingual<string>;
  };
  jobTitle?: MultiLingual<string>;
  stage: typeof JOB_APPLICATION_INVITATION_ACTION_TYPE[keyof typeof JOB_APPLICATION_INVITATION_ACTION_TYPE];
  applicationDeclinedOrRevokedReasons?: Array<MultiLingual<string>>;
  activityDate?: Date;
  isResumePurchased?: boolean;
  isJobActive: boolean;
}

type FirestoreQueryParams = [
  Query<unknown>,
  ...Array<
    | QueryOrderByConstraint
    | QueryFieldFilterConstraint
    | QueryStartAtConstraint
    | QueryEndAtConstraint
    | QueryLimitConstraint
  >
];

interface DeclinedReasonsFormData {
  declinedReasons: Array<
    keyof typeof EMPLOYER_DECLINED_CANDIDATE_REASONS_T_LABELS
  >;
  otherDeclinedReason: string;
}

const COLUMNS: ReadonlyArray<Column> = [
  {
    id: "candidate",
    label: "t_general_candidate",
    align: "left",
    enableSort: false
  },
  {
    id: "jobTitle",
    label: "t_general_job_title",
    enableSort: false
  },
  {
    id: "stage",
    label: "t_general_stage",
    enableSort: true
  },
  {
    id: "resumePurchaseStatus",
    label: "t_general_purchased"
  },
  {
    id: "activityDate",
    label: "t_general_activity_date",
    enableSort: true
  }
];

const StyledTablePagination = styled(TablePagination)({
  "& .MuiTablePagination-select, & .MuiInputBase-root": {
    width: "max-content"
  }
});

const ApplicantsDataTable = ({
  filterJobId = ""
}: ApplicantsDataTableProps) => {
  const [user] = useAuthState(auth);
  const companyData = useCompanyDetails();
  const navigate = useNavigate();
  const toast = useToast();
  const userProfile = useUserProfile();
  const [getCandidateDetails] = useHttpsCallable(
    functions,
    "getCandidateDetails"
  );

  // Company variables
  const companyId = userProfile.value?.company_id ?? "";

  // Job variables
  const jobTitleOptions =
    resolveMultiLingual(companyData.value?.job_titles) ?? {};

  // Table data variables
  const EMPLOYER_DECLINED_CANDIDATE_REASONS_OPTIONS = useOptions(
    EMPLOYER_DECLINED_CANDIDATE_REASONS,
    EMPLOYER_DECLINED_CANDIDATE_REASONS_T_LABELS
  ).map((singleOption) => {
    return { value: singleOption.key, label: singleOption.label };
  });

  const [tableData, setTableData] = useState<Record<string, Row | undefined>>(
    {}
  );
  const [loading, setLoading] = useState<boolean>(true);

  // Drawer variables
  const [openCandidateProfileDrawerIndex, setOpenCandidateProfileDrawerIndex] =
    useState<string>("");

  // Sorting and Pagination variables
  const [totalApplications, setTotalApplications] = useState<
    number | undefined
  >(undefined);
  const [totalApplicationsLoading, setTotalApplicationsLoading] =
    useState<boolean>(true);

  const [sortedBy, setSortedBy] = useState<Column["id"]>("activityDate");
  const [orderDirection, setOrderDirection] = useState<
    "asc" | "desc" | undefined
  >("desc");

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    PAGINATION.TABLE_ROWS_PER_PAGE[0]
  );
  const [pageDirection, setPageDirection] = useState<"none" | "prev" | "next">(
    "none"
  );
  const [lastJob, setLastJob] = useState<DocumentData>();
  const [firstJob, setFirstJob] = useState<DocumentData>();

  const applicationStatus = [
    JOB_APPLICATION_INVITATION_ACTION_TYPE.APPLIED,
    JOB_APPLICATION_INVITATION_ACTION_TYPE.RESUME_PURCHASED,
    JOB_APPLICATION_INVITATION_ACTION_TYPE.DECLINED,
    JOB_APPLICATION_INVITATION_ACTION_TYPE.REVOKED,
    JOB_APPLICATION_INVITATION_ACTION_TYPE.HIRING_FLOW_STEP_CHANGED,
    JOB_APPLICATION_INVITATION_ACTION_TYPE.HIRED
  ];

  // Fetching application count
  const getApplicationCount = async () => {
    try {
      setTotalApplicationsLoading(true);
      const applicationsRef = collectionGroup(
        db,
        FIRESTORE_COLLECTIONS.APPLICATIONS
      );
      const applicationQuery = query(
        applicationsRef,
        where("company_id", "==", companyId),
        where("metadata.status", "in", applicationStatus)
      );
      const querySnapshot = await getCountFromServer(applicationQuery);
      setTotalApplications(querySnapshot.data().count);
      setTotalApplicationsLoading(false);
    } catch (error) {
      toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
    }
  };

  useEffect(() => {
    if (companyId) {
      getApplicationCount();
    }
  }, [companyId]);

  useEffect(() => {
    const styleTagId = "body-drawer-style";
    const styleTag = document.getElementById(styleTagId);

    if (openCandidateProfileDrawerIndex) {
      if (!styleTag) {
        document.head.insertAdjacentHTML(
          "beforeend",
          `<style id="${styleTagId}">body{overflow:hidden!important}</style>`
        );
      }
    } else if (styleTag) {
      styleTag.remove();
    }

    return () => styleTag?.remove();
  }, [openCandidateProfileDrawerIndex]);

  // validation schema
  const schema = yup.object({
    declinedReasons: yup.array(),
    otherDeclinedReason: yup
      .string()
      .trim()
      .max(
        FREE_TEXT_FIELD_MAX_LENGTH,
        intl.get("t_error_max_limit", {
          field: intl.get("t_general_first_name"),
          maxLimit: FREE_TEXT_FIELD_MAX_LENGTH
        })
      )
  });

  const declinedReasonsMethods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      declinedReasons: [],
      otherDeclinedReason: ""
    }
  });

  const {
    handleSubmit: declinedReasonsHandleSubmit,
    control: declinedReasonsControl,
    setValue: declinedReasonsSetValue
  } = declinedReasonsMethods;

  const getOrderBy = (sortedBy: string) => {
    const orderClauses = [];
    switch (sortedBy) {
      case "jobTitle":
        {
          const titleEnOrderBy = orderBy(
            "metadata.job_title.en",
            orderDirection
          );
          const titleJaOrderBy = orderBy(
            "metadata.job_title.ja",
            orderDirection
          );
          if (translate.getCurrentLocaleShort() === LOCALE_SHORT.EN) {
            orderClauses.push(titleEnOrderBy);
            orderClauses.push(titleJaOrderBy);
          } else {
            orderClauses.push(titleJaOrderBy);
            orderClauses.push(titleEnOrderBy);
          }
        }
        break;
      case "stage":
        orderClauses.push(orderBy("metadata.status", orderDirection));
        break;
      case "activityDate":
      default:
        orderClauses.push(orderBy("metadata.updated_at", orderDirection));
        break;
    }

    return orderClauses;
  };

  const getWhereBy = (searchedJobId = "") => {
    if (searchedJobId) {
      return where("job_id", "==", searchedJobId);
    } else {
      // Here, companyId is global, so not passed through parameter.
      return where("company_id", "==", companyId);
    }
  };

  //Decline Job
  const handleDeclineSuccess = () => {
    toast.kampai(intl.get("t_toast_decline_candidate_success"), "success");
  };

  const handleDeclineFail = () => {
    toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
  };

  const handleDeclineJob = async (
    userData: Row,
    declinedReasons?: Array<MultiLingual<string>>
  ) => {
    const { applicationId, candidate, jobId } = userData;
    try {
      if (applicationId && candidate.id && jobId) {
        const success = await handleJobDecline(
          applicationId,
          jobId,
          candidate.id,
          declinedReasons
        );

        if (success) {
          handleDeclineSuccess();
          const updatedRowData = tableData[applicationId];

          if (updatedRowData) {
            updatedRowData.stage =
              JOB_APPLICATION_INVITATION_ACTION_TYPE.DECLINED;
            updatedRowData.applicationDeclinedOrRevokedReasons =
              declinedReasons;
            setTableData((prevData) => ({
              ...prevData,
              [applicationId]: updatedRowData
            }));
          }
        } else {
          handleDeclineFail();
        }
      }
    } catch (error) {
      handleDeclineFail();
    }
  };

  const handleDeclineModalClose = async (
    reason: keyof typeof DIALOG_ACTION,
    applicationId: ApplicationID
  ) => {
    if (reason === DIALOG_ACTION.AGREE) {
      const selectedRow = tableData[applicationId];
      if (selectedRow) {
        handleDeclineJob(selectedRow);
      }
    }
  };

  const DeclineButton = ({
    stage,
    applicationId,
    candidateId,
    jobId
  }: DeclineButtonProps) => {
    const handleDeclinedReasonsFormSubmit = async (
      formData: DeclinedReasonsFormData
    ) => {
      const { declinedReasons } = formData;
      const allDeclinedReasons = [
        ...declinedReasons
        // formData.otherDeclinedReason // FIXME: add this in phase-2(handle email and phone number check for text field)
      ]?.map((singleDeclinedReason) => {
        return {
          en: intlEn.get(
            EMPLOYER_DECLINED_CANDIDATE_REASONS_T_LABELS[
              singleDeclinedReason as keyof typeof EMPLOYER_DECLINED_CANDIDATE_REASONS_T_LABELS
            ]
          ),
          ja: intlJa.get(
            EMPLOYER_DECLINED_CANDIDATE_REASONS_T_LABELS[
              singleDeclinedReason as keyof typeof EMPLOYER_DECLINED_CANDIDATE_REASONS_T_LABELS
            ]
          )
        };
      });

      try {
        if (applicationId && candidateId && jobId) {
          const success = await handleJobDecline(
            applicationId,
            jobId,
            candidateId,
            allDeclinedReasons
          );

          if (success) {
            handleDeclineSuccess();
            const updatedRowData = tableData[applicationId];

            if (updatedRowData) {
              updatedRowData.stage =
                JOB_APPLICATION_INVITATION_ACTION_TYPE.DECLINED;
              updatedRowData.applicationDeclinedOrRevokedReasons =
                allDeclinedReasons;
              setTableData((prevData) => ({
                ...prevData,
                [applicationId]: updatedRowData
              }));
            }
          } else {
            handleDeclineFail();
          }
        }
      } catch (error) {
        handleDeclineFail();
      }
    };

    if (stage === JOB_APPLICATION_INVITATION_ACTION_TYPE.DECLINED) {
      return (
        <Typography
          className="additional-column"
          variant="subtitle5"
          color="text.secondary">
          {intl.get("t_employer_dashboard_application_already_declined")}
        </Typography>
      );
    } else if (
      stage === JOB_APPLICATION_INVITATION_ACTION_TYPE.INVITED ||
      stage === JOB_APPLICATION_INVITATION_ACTION_TYPE.REVOKED ||
      stage === JOB_APPLICATION_INVITATION_ACTION_TYPE.INVITED_DECLINED ||
      stage === JOB_APPLICATION_INVITATION_ACTION_TYPE.HIRED
    ) {
      return (
        <Typography
          className="additional-column"
          variant="subtitle5"
          color="text.secondary">
          {intl.get("t_employer_dashboard_application_cannot_decline")}
        </Typography>
      );
    } else {
      return (
        <Dialog
          isStopEventPropagation
          title={intl.get("t_employer_declined_candidate_dialog_title")}
          maxWidth="sm"
          initiator={
            <Typography
              className="additional-column"
              variant="subtitle5"
              color="text.secondary"
              sx={{
                "cursor": "pointer",
                "&:hover": { textDecoration: "underline" }
              }}>
              {intl.get(
                "t_employer_dashboard_job_applications_table_column_decline"
              )}
            </Typography>
          }
          onClose={(reason) => handleDeclineModalClose(reason, applicationId)}>
          {(handleAgree, handleCancel) => (
            <>
              <Box
                noValidate
                component="form"
                onSubmit={declinedReasonsHandleSubmit(
                  handleDeclinedReasonsFormSubmit
                )}>
                <DialogContent sx={{ py: 1 }}>
                  <Typography color="text.secondary" variant="body1">
                    {intl.get("t_employer_declined_candidate_dialog_subtitle")}
                  </Typography>
                  <Checkbox
                    size="medium"
                    name="declinedReasons"
                    control={declinedReasonsControl}
                    setValue={declinedReasonsSetValue}
                    options={EMPLOYER_DECLINED_CANDIDATE_REASONS_OPTIONS}
                  />
                  {/* FIXME: this is phase2 - need to handle email and phone number check */}
                  {/* <TextField
                    control={declinedReasonsControl}
                    name="otherDeclinedReason"
                    placeholder={intl.get("t_general_other_decline_reason")}
                  /> */}
                </DialogContent>
                <DialogActions>
                  <Button handleClick={handleCancel} variant="outlined">
                    {intl.get("t_dialog_decline_candidate_cancel_button")}
                  </Button>
                  <Button type="submit">
                    {intl.get("t_dialog_decline_candidate_agree_button")}
                  </Button>
                </DialogActions>
              </Box>
            </>
          )}
        </Dialog>
      );
    }
  };

  //Sorting and Pagination
  const handleSortRequest = (sortBy: Column["id"]) => {
    // No point in performing sort when table is not available / loaded.
    if (loading || !totalApplications) {
      return;
    }

    // Some fields are non sortable.
    if (sortBy == "candidate" || sortBy == "jobTitle") {
      return;
    }

    setSortedBy(sortBy);
    setOrderDirection(orderDirection === "asc" ? "desc" : "asc");
    // reset pagination
    setLastJob(undefined);
    setFirstJob(undefined);
    setPage(0);
    setPageDirection("none");
  };

  const handleChangePage = (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (page < newPage) {
      setPageDirection("next");
    } else {
      setPageDirection("prev");
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    // reset pagination
    setLastJob(undefined);
    setFirstJob(undefined);
    setPage(0);
    setPageDirection("none");
  };

  // Fetching applications
  const fetchApplications = async () => {
    try {
      if (companyId && totalApplications && totalApplications > 0) {
        setLoading(true);

        const jobRef = collection(db, FIRESTORE_COLLECTIONS.JOBS);
        const applicationsRef = collectionGroup(
          db,
          FIRESTORE_COLLECTIONS.APPLICATIONS
        );

        const applications: Array<Application> = [];

        const queryParams: FirestoreQueryParams = [applicationsRef];
        queryParams.push(getWhereBy(filterJobId));
        queryParams.push(where("metadata.status", "in", applicationStatus));
        queryParams.push(...getOrderBy(sortedBy));

        switch (pageDirection) {
          case "next":
            {
              if (lastJob) {
                queryParams.push(startAfter(lastJob));
              }
              queryParams.push(limit(rowsPerPage));
            }
            break;
          case "prev":
            {
              if (firstJob) {
                queryParams.push(endBefore(firstJob));
              }
              queryParams.push(limitToLast(rowsPerPage));
            }
            break;
          case "none":
          default:
            queryParams.push(limit(rowsPerPage));
            break;
        }

        const applicationQuery = query(...queryParams);

        const querySnapshot = await getDocs(applicationQuery);
        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            applications.push({
              applicationId: doc.id,
              ...(doc.data() as JobApplicationInvitation)
            });
          });

          setFirstJob(querySnapshot.docs[0]);
          setLastJob(querySnapshot.docs[querySnapshot.docs.length - 1]);

          const emptyTableData: Record<string, undefined> = {};
          for (const singleApplication of applications) {
            emptyTableData[singleApplication.applicationId] = undefined;
          }

          setTableData(emptyTableData);

          for (const singleApplication of applications) {
            (async (applicationData: Application) => {
              // Fetch job title from Job Id
              const jobDocRef = doc(jobRef, applicationData.job_id);
              const jobDocSnapshot = await getDoc(jobDocRef);

              if (jobDocSnapshot.exists()) {
                const jobData = jobDocSnapshot.data();
                applicationData.job = jobData as JobProfile;
              }

              const lastActionIndex = applicationData.actions.length - 1 ?? 0;

              let singleCandidateDetail = undefined;

              try {
                const result = await getCandidateDetails({
                  companyId,
                  candidateIds: [applicationData.candidate_id]
                });
                const candidateDetails = result?.data as Record<
                  string,
                  CandidateDetails
                >;
                singleCandidateDetail =
                  candidateDetails[applicationData.candidate_id];
              } catch (e) {
                /* no-op */
              }

              const lastActionType =
                applicationData.actions[lastActionIndex].action_type;

              // FIXME: Why is jobTitle not required to resolve here, but name / initials needs to be done.
              const tableRow: Row = {
                applicationId: applicationData.applicationId,
                hiringFlowActiveStepKey:
                  applicationData.hiring_flow_active_step_key ?? "",
                jobId: applicationData.job_id,
                jobData: applicationData.job,
                applicationDeclinedOrRevokedReasons:
                  JOB_APPLICATION_INVITATION_ACTION_TYPE.DECLINED ===
                    lastActionType ||
                  JOB_APPLICATION_INVITATION_ACTION_TYPE.REVOKED ===
                    lastActionType
                    ? applicationData.actions[lastActionIndex].comments
                    : [],
                stage:
                  lastActionType ===
                  JOB_APPLICATION_INVITATION_ACTION_TYPE.RESUME_PURCHASED
                    ? applicationData.actions[lastActionIndex - 1].action_type
                    : lastActionType,
                activityDate:
                  applicationData.actions[lastActionIndex].updated_at.toDate(),
                jobTitle: applicationData.job?.job_title,
                candidate: {
                  id: applicationData.candidate_id,
                  fullName:
                    resolveMultiLingual(singleCandidateDetail?.name) ?? "",
                  initials:
                    resolveMultiLingual(singleCandidateDetail?.initials) ?? "",
                  photoUrl: singleCandidateDetail?.photo_url ?? "",
                  jobTitle: singleCandidateDetail?.current_job_title
                },
                isResumePurchased:
                  !singleCandidateDetail?.is_masked_info ?? false,
                isJobActive:
                  applicationData.job?.status || applicationData.job?.statusV2
                    ? applicationData.job?.status ==
                        JOB_POSTING_STATUS.OK_AUTO_REVIEWED ||
                      applicationData.job?.status ==
                        JOB_POSTING_STATUS.OK_MANUAL_REVIEWED ||
                      applicationData.job?.statusV2.en ==
                        JOB_POSTING_STATUS.OK_AUTO_REVIEWED ||
                      applicationData.job?.statusV2.en ==
                        JOB_POSTING_STATUS.OK_MANUAL_REVIEWED ||
                      applicationData.job?.statusV2.ja ==
                        JOB_POSTING_STATUS.OK_AUTO_REVIEWED ||
                      applicationData.job?.statusV2.ja ==
                        JOB_POSTING_STATUS.OK_MANUAL_REVIEWED
                    : false
              };

              setTableData((prevData) => ({
                ...prevData,
                [applicationData.applicationId]: tableRow
              }));
            })(singleApplication);
          }
        } else {
          setTableData({});
        }
        setLoading(false);
      } else if (totalApplications == 0) {
        setLoading(false);
        setTableData({});
      } else {
        setTableData({});
      }
    } catch (error) {
      toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
    }
  };

  useEffect(() => {
    fetchApplications();
  }, [
    rowsPerPage,
    page,
    orderDirection,
    sortedBy,
    companyId,
    totalApplications
  ]);

  const StyledList = styled(List)({
    "listStyleType": "disc",
    "padding": "0.25rem 1.25rem",
    "& .MuiListItem-root": {
      padding: 0,
      display: "list-item"
    }
  });

  useEffect(() => {
    // Reset pagination, firstJob, and lastJob when searchedJob changes
    setPage(0);
    setPageDirection("none");
    setFirstJob(undefined);
    setLastJob(undefined);

    // Fetch applications again with the updated searchedJob
    fetchApplications();
  }, [filterJobId]);

  const handleError = () => {
    setOpenCandidateProfileDrawerIndex("");
    toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
  };

  const handleTableRowData = (
    applicationId: ApplicationID,
    updatedTableData: Row
  ) => {
    setTableData({
      ...tableData,
      [applicationId]: { ...tableData[applicationId], ...updatedTableData }
    });
  };

  return loading || totalApplicationsLoading ? (
    <SkeletonJobApplicationDataTable />
  ) : totalApplications == 0 && Object.keys(jobTitleOptions).length === 0 ? (
    // No job posted yet
    <Stack spacing={3} height={400} justifyContent="center" alignItems="center">
      <Typography variant="h3">
        {intl.get("t_employer_dashboard_job_posted_table_no_posted_jobs_yet")}
      </Typography>
      {user?.emailVerified ? (
        <Button
          size="medium"
          endAdornment={<NavigateNextIcon />}
          handleClick={() =>
            navigate(
              `/${translate.getCurrentLocale()}/employers/jobs/new/${translate.getCurrentLocaleShort()}/company-information`
            )
          }>
          {intl.get("t_employer_dashboard_post_job_button")}
        </Button>
      ) : (
        <Tooltip
          arrow
          placement="bottom"
          enterTouchDelay={0}
          title={intl.get("t_employer_dashboard_post_job_disabled_tooltip")}>
          <span>
            <Button disabled size="medium" endAdornment={<NavigateNextIcon />}>
              {intl.get("t_employer_dashboard_post_job_button")}
            </Button>
          </span>
        </Tooltip>
      )}
    </Stack>
  ) : Object.keys(tableData).length === 0 ? (
    // Search result not found
    filterJobId ? (
      <Stack
        spacing={3}
        height={400}
        justifyContent="center"
        alignItems="center">
        <Typography variant="h3">
          {intl.get(
            "t_employer_dashboard_job_applications_table_no_search_result_title"
          )}
        </Typography>
        <Typography variant="h6">
          {intl.get(
            "t_employer_dashboard_job_applications_table_no_search_result_subtitle"
          )}
        </Typography>
      </Stack>
    ) : (
      // No applicants yet
      <Stack
        spacing={3}
        height={400}
        justifyContent="center"
        alignItems="center">
        <Typography variant="h3">
          {intl.get(
            "t_employer_dashboard_job_applications_table_no_applicants_yet"
          )}
        </Typography>
        <Button
          size="medium"
          endAdornment={<NavigateNextIcon />}
          handleClick={() =>
            navigate(`/${translate.getCurrentLocale()}/employers/search`)
          }>
          {intl.get("t_general_invite_candidates")}
        </Button>
      </Stack>
    )
  ) : (
    <>
      {/* Applicants table with data */}
      <DataTableWrapper>
        <TableHead>
          <TableRow>
            {COLUMNS?.map((singleColumn) => (
              <TableCell
                key={singleColumn.id}
                width="14.28%"
                align={singleColumn?.align ?? "center"}>
                {singleColumn.enableSort ? (
                  <TableSortLabel
                    sx={{
                      pl:
                        !singleColumn?.align || singleColumn?.align == "center"
                          ? 3
                          : 0
                    }}
                    onClick={() => handleSortRequest(singleColumn.id)}
                    active={sortedBy === singleColumn.id}
                    direction={orderDirection}>
                    <Typography variant="subtitle5">
                      {intl.get(singleColumn.label)}
                    </Typography>
                  </TableSortLabel>
                ) : (
                  <Typography variant="subtitle5">
                    {intl.get(singleColumn.label)}
                  </Typography>
                )}
              </TableCell>
            ))}
            {/* Note: Last and Second Last TableCell has no title. Used to display actions like share, edit on hover */}
            <EnvironmentSpecific env={ENVIRONMENT.STAGE}>
              <TableCell></TableCell>
            </EnvironmentSpecific>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        {Object.keys(tableData).map((applicationId) => {
          const singleData = tableData[applicationId];
          if (!singleData) {
            return (
              <TableRow key={applicationId}>
                <TableCell align="left">
                  <Stack
                    direction="row"
                    alignItems="center"
                    gap={1}
                    width="100%">
                    <SkeletonAvatar size="medium" />
                    <Stack width="calc(100% - 2.5rem)">
                      <SkeletonTypography variant="subtitle5" />
                      <SkeletonTypography variant="body2" />
                    </Stack>
                  </Stack>
                </TableCell>
                <TableCell align="center">
                  <SkeletonTypography variant="body2" width="short" />
                </TableCell>
                <TableCell align="center">
                  <Stack alignItems="center">
                    <SkeletonStatus />
                  </Stack>
                </TableCell>
                <TableCell align="center">
                  <Stack alignItems="center">
                    <SkeletonAvatar size="small" />
                  </Stack>
                </TableCell>
                <EnvironmentSpecific env={ENVIRONMENT.STAGE}>
                  <TableCell align="center">
                    <SkeletonTypography variant="body2" width="short" />
                  </TableCell>
                </EnvironmentSpecific>
                <TableCell align="center">
                  <SkeletonTypography variant="body2" width="x-short" />
                </TableCell>
                <TableCell align="center">
                  <SkeletonTypography variant="body2" width="short" />
                </TableCell>
              </TableRow>
            );
          }
          return (
            <Drawer
              key={applicationId}
              initiatorStyle={{
                display: "table-row-group"
              }}
              initiatorComponent="tbody"
              initiator={
                <TableRow
                  className="hoverable-row"
                  sx={{ cursor: "pointer !important" }}>
                  <TableCell>
                    <Stack direction="row" alignItems="center" gap={1}>
                      <Avatar
                        allowAddPhoto={false}
                        imgSrc={singleData.candidate.photoUrl}
                        fullName={singleData.candidate.fullName}
                        initials={singleData.candidate.initials}
                        size="medium"
                      />
                      <Stack>
                        {singleData?.isResumePurchased ? (
                          <Typography variant="subtitle5">
                            {singleData.candidate.fullName}
                          </Typography>
                        ) : (
                          <Typography
                            variant="subtitle5"
                            sx={{
                              filter: "blur(4px)",
                              userSelect: "none"
                            }}>
                            {intl.get(
                              "t_employer_resume_purchase_table_candidate_name"
                            )}
                          </Typography>
                        )}
                        <Typography variant="body2">
                          {resolveMultiLingual(singleData.candidate.jobTitle) ??
                            intl.get("t_general_fresher")}
                        </Typography>
                        <EnvironmentSpecific env={ENVIRONMENT.STAGE}>
                          <Typography
                            variant="helperText"
                            component="p"
                            display="block">
                            {singleData.candidate.id}
                          </Typography>
                        </EnvironmentSpecific>
                      </Stack>
                    </Stack>
                  </TableCell>
                  <TableCell align="center">
                    {singleData.isJobActive ? (
                      <>
                        <Link
                          href={`/${translate.getCurrentLocale()}/jobs/${
                            singleData.jobId
                          }`}
                          target="_blank"
                          variant="body2"
                          onClick={(event) => {
                            event.stopPropagation();
                          }}
                          color="inherit"
                          underline="none"
                          rel="noopener noreferrer"
                          sx={{
                            "cursor": "pointer",
                            "&:hover": { textDecoration: "underline" }
                          }}
                          display="block">
                          {resolveMultiLingual(singleData.jobTitle)}
                        </Link>
                        <EnvironmentSpecific env={ENVIRONMENT.STAGE}>
                          <Link
                            href={`/${translate.getCurrentLocale()}/jobs/${
                              singleData.jobId
                            }`}
                            target="_blank"
                            variant="helperText"
                            onClick={(event) => {
                              event.stopPropagation();
                            }}
                            color="inherit"
                            underline="none"
                            rel="noopener noreferrer"
                            sx={{
                              "cursor": "pointer",
                              "&:hover": { textDecoration: "underline" }
                            }}
                            display="block">
                            {singleData.jobId}
                          </Link>
                        </EnvironmentSpecific>
                      </>
                    ) : (
                      <Tooltip
                        title={
                          <Typography variant="body2">
                            {intl.get(
                              "t_employer_dashboard_application_job_not_in_search"
                            )}
                          </Typography>
                        }>
                        <div>
                          <Link
                            href={`/${translate.getCurrentLocale()}/jobs/${
                              singleData.jobId
                            }`}
                            target="_blank"
                            variant="body2"
                            onClick={(event) => {
                              event.stopPropagation();
                            }}
                            color="inherit"
                            underline="none"
                            rel="noopener noreferrer"
                            sx={{
                              "cursor": "pointer",
                              "&:hover": { textDecoration: "underline" }
                            }}
                            display="block">
                            {resolveMultiLingual(singleData.jobTitle)}
                          </Link>
                          <EnvironmentSpecific env={ENVIRONMENT.STAGE}>
                            <Link
                              href={`/${translate.getCurrentLocale()}/jobs/${
                                singleData.jobId
                              }`}
                              target="_blank"
                              variant="helperText"
                              onClick={(event) => {
                                event.stopPropagation();
                              }}
                              color="inherit"
                              underline="none"
                              rel="noopener noreferrer"
                              sx={{
                                "cursor": "pointer",
                                "&:hover": { textDecoration: "underline" }
                              }}
                              display="block">
                              {singleData.jobId}
                            </Link>
                          </EnvironmentSpecific>
                        </div>
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <Stack
                      width="100%"
                      justifyContent="center"
                      alignItems="center">
                      <Stack
                        width="max-content"
                        position="relative"
                        direction="row"
                        alignItems="center"
                        columnGap={1}
                        justifyContent="center">
                        {singleData.stage ===
                          JOB_APPLICATION_INVITATION_ACTION_TYPE.HIRING_FLOW_STEP_CHANGED &&
                        singleData?.jobData?.hiring_flow ? (
                          <Status
                            label={singleData.stage}
                            labelText={
                              generateHiringProcessSteps(
                                singleData?.jobData?.hiring_flow
                              ).find(
                                (singleHiringProcessStep) =>
                                  singleHiringProcessStep?.key ===
                                  singleData?.hiringFlowActiveStepKey
                              )?.label
                            }
                          />
                        ) : (
                          <Status label={singleData.stage} />
                        )}
                        {singleData.applicationDeclinedOrRevokedReasons &&
                        singleData.applicationDeclinedOrRevokedReasons.length >
                          0 &&
                        (singleData.stage ===
                          JOB_APPLICATION_INVITATION_ACTION_TYPE.DECLINED ||
                          singleData.stage ===
                            JOB_APPLICATION_INVITATION_ACTION_TYPE.REVOKED) ? (
                          <Stack
                            justifyContent="center"
                            alignItems="center"
                            position="absolute"
                            right={-28}>
                            <Tooltip
                              enterTouchDelay={0}
                              arrow
                              title={
                                <Box px={1}>
                                  <Typography mb={0.5} variant="body1">
                                    {`${intl.get(
                                      JOB_APPLICATION_INVITATION_ACTION_TYPE_T_LABELS[
                                        singleData.stage
                                      ]
                                    )} ${intl.get("t_general_reasons")}:`}
                                  </Typography>
                                  <StyledList>
                                    <Stack rowGap={0.5}>
                                      {singleData?.applicationDeclinedOrRevokedReasons?.map(
                                        (
                                          singleApplicationDeclinedOrRevokedReason: MultiLingual<string>,
                                          index: number
                                        ) => {
                                          const declinedReason: string =
                                            resolveMultiLingual(
                                              singleApplicationDeclinedOrRevokedReason
                                            ) ?? "";
                                          return (
                                            <>
                                              {declinedReason ? (
                                                <ListItem key={index}>
                                                  {declinedReason}
                                                </ListItem>
                                              ) : (
                                                false
                                              )}
                                            </>
                                          );
                                        }
                                      )}
                                    </Stack>
                                  </StyledList>
                                </Box>
                              }>
                              <InfoOutlinedIcon />
                            </Tooltip>
                          </Stack>
                        ) : (
                          false
                        )}
                      </Stack>
                    </Stack>
                    <EnvironmentSpecific env={ENVIRONMENT.STAGE}>
                      <Typography
                        variant="helperText"
                        component="p"
                        display="block">
                        {singleData.applicationId}
                      </Typography>
                    </EnvironmentSpecific>
                  </TableCell>
                  <TableCell align="center">
                    <CheckCircleRoundedIcon
                      color={
                        singleData?.isResumePurchased ? "primary" : "disabled"
                      }
                    />
                  </TableCell>
                  <TableCell align="center">
                    {dayjs(singleData?.activityDate)
                      .locale(translate.getCurrentLocaleShort())
                      .fromNow()}
                  </TableCell>
                  <EnvironmentSpecific env={ENVIRONMENT.STAGE}>
                    <TableCell align="center">
                      <Tooltip
                        enterTouchDelay={0}
                        title={
                          singleData?.isResumePurchased
                            ? intl.get(
                                "t_employer_dashboard_job_applications_table_column_message_tooltip_for_purchased_resume"
                              )
                            : intl.get(
                                "t_employer_dashboard_job_applications_table_column_message_tooltip_for_not_purchased_resume"
                              )
                        }
                        arrow>
                        <Box
                          pt={1}
                          onClick={(event) => event.stopPropagation()}>
                          {singleData?.isResumePurchased ? (
                            <Link
                              component={ReactRouterLink}
                              to={`/${translate.getCurrentLocale()}/employers/conversations/new?application_id=${applicationId}&candidate_id=${
                                singleData.candidate.id
                              }&job_id=${singleData.jobId}`}
                              target="_blank"
                              rel="noopener noreferrer">
                              <ChatRoundedIcon
                                color={
                                  singleData?.isResumePurchased
                                    ? "primary"
                                    : "disabled"
                                }
                              />
                            </Link>
                          ) : (
                            <ChatRoundedIcon
                              sx={{ cursor: "not-allowed" }}
                              color={
                                singleData?.isResumePurchased
                                  ? "primary"
                                  : "disabled"
                              }
                            />
                          )}
                        </Box>
                      </Tooltip>
                    </TableCell>
                  </EnvironmentSpecific>
                  <TableCell align="center">
                    <DeclineButton
                      stage={singleData.stage}
                      applicationId={singleData.applicationId}
                      candidateId={singleData.candidate.id}
                      jobId={singleData.jobId}
                    />
                  </TableCell>
                </TableRow>
              }
              drawerContent={
                <AppliedResumeDrawer
                  tableRowData={singleData}
                  handleTableRowData={handleTableRowData}
                  candidateId={singleData.candidate?.id}
                  candidateProfilePicUrl={singleData.candidate?.photoUrl}
                  applicationId={singleData.applicationId}
                  hiringFlowActiveStepKey={singleData.hiringFlowActiveStepKey}
                  jobId={singleData.jobId}
                  jobData={singleData.jobData}
                  applicationStatus={singleData.stage}
                  handleDecline={(declinedReasons) => {
                    handleDeclineJob(singleData, declinedReasons);
                  }}
                  handleError={handleError}
                />
              }
              isDrawerOpen={openCandidateProfileDrawerIndex === applicationId}
              setIsDrawerOpen={(drawerOpenStatus) => {
                if (drawerOpenStatus === true) {
                  setOpenCandidateProfileDrawerIndex(applicationId);
                } else {
                  setOpenCandidateProfileDrawerIndex("");
                }
              }}
            />
          );
        })}

        <TableFooter>
          <TableRow>
            <StyledTablePagination
              rowsPerPageOptions={PAGINATION.TABLE_ROWS_PER_PAGE}
              count={totalApplications ?? 0}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage={intl.get("t_general_table_pagination_label")}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </DataTableWrapper>
    </>
  );
};

export default ApplicantsDataTable;
