import { useEffect, useState } from "react";

import {
  matchPath,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams
} from "react-router-dom";

import { ContentState, convertToRaw, RawDraftContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { doc, getDoc } from "firebase/firestore";
import htmlToDraft from "html-to-draftjs";
import { useHttpsCallable } from "react-firebase-hooks/functions";

import useCompanyDetails from "@hooks/database/useCompanyDetails";
import useToast from "@hooks/useToast";

import JobID from "@interfaces/database/JobID";
import JobProfile from "@interfaces/database/JobProfile";
import MultiLingual from "@interfaces/database/MultiLingual";

import {
  FIRESTORE_COLLECTIONS,
  JOB_POSTING_STATUS,
  LOCALE_SHORT
} from "@utils/config";
import { db, functions } from "@utils/firebase";
import translate, { intl } from "@utils/translate";

type JobProfileWithoutFields = Pick<
  JobProfile,
  "must_requirements" | "job_description" | "additional_information"
>;

type FieldKey =
  | "must_requirements"
  | "job_description"
  | "additional_information";

export interface AutoTranslatorResponse {
  success: boolean;
  translatedText: string;
}

const EmployersPostJob = () => {
  const { job_id: jobId, data_locale_short: dataLocaleShort } = useParams();
  const [translateText] = useHttpsCallable(functions, "autoTranslate");
  const [searchParams] = useSearchParams();
  const copyFromJobId = searchParams.get("copy_from_job_id") ?? "";
  const navigate = useNavigate();
  const { pathname: originalPathname } = useLocation();
  const toast = useToast();
  const companyDetails = useCompanyDetails();

  const [jobData, setJobData] = useState<JobProfile>();

  const pathname = translate.getNonLocalizedURL(originalPathname);

  const isMatch = matchPath(
    "/employers/jobs/:job_id/:data_locale_short",
    pathname
  );
  const isJobInformationStep = matchPath(
    "/employers/jobs/:job_id/:data_locale_short/job-information",
    pathname
  );
  const isAdditionalInformationStep = matchPath(
    "/employers/jobs/:job_id/:data_locale_short/additional-information",
    pathname
  );
  const isConfirmationStep = matchPath(
    "/employers/jobs/:job_id/:data_locale_short/confirmation",
    pathname
  ); // this is last step for entering job data

  useEffect(() => {
    // check local value is valid or not
    if (
      dataLocaleShort !== LOCALE_SHORT.EN &&
      dataLocaleShort !== LOCALE_SHORT.JA
    ) {
      navigate("/not-found");
    }
  }, [dataLocaleShort]);

  useEffect(() => {
    if (isMatch !== null) {
      navigate(
        `/${translate.getCurrentLocale()}/employers/jobs/${jobId}/${dataLocaleShort}/company-information`
      );
    }
    if (jobId === "new" && !pathname.includes("/company-information")) {
      navigate(
        `/${translate.getCurrentLocale()}/employers/jobs/${jobId}/${dataLocaleShort}/company-information`
      );
    }

    if (jobId && jobId !== "new") {
      // fetch job data for edit operation
      const getJobData = async () => {
        const jobDocRef = doc(db, FIRESTORE_COLLECTIONS.JOBS, jobId);
        const jobDoc = await getDoc(jobDocRef);
        if (jobDoc.exists()) {
          setJobData(jobDoc.data() as JobProfile);
        } else {
          navigate(
            `/${translate.getCurrentLocale()}/employers/jobs/new/${dataLocaleShort}/company-information`
          );
        }
      };
      getJobData();
    }
  }, [isMatch, jobId, copyFromJobId]);

  const translateField = async (
    fieldKey: FieldKey,
    jobData: JobProfileWithoutFields,
    sourceLocale: keyof MultiLingual<RawDraftContentState>,
    targetLocale: keyof MultiLingual<RawDraftContentState>
  ) => {
    const sourceField = (
      jobData[fieldKey] as MultiLingual<RawDraftContentState>
    )[sourceLocale];
    const targetField = (
      jobData[fieldKey] as MultiLingual<RawDraftContentState>
    )[targetLocale];

    if (sourceField && !targetField) {
      try {
        // Convert the content from Draft.js format to HTML
        const textToTranslate = draftToHtml(sourceField);

        // Call the translation API
        const res = await translateText({
          textToTranslate,
          local: targetLocale
        });

        const translatedData = res?.data as AutoTranslatorResponse;

        if (translatedData?.success) {
          // Convert the translated HTML back to Draft.js format
          const blocksFromHtml = htmlToDraft(translatedData?.translatedText);
          const { contentBlocks, entityMap } = blocksFromHtml;
          const contentState = ContentState.createFromBlockArray(
            contentBlocks,
            entityMap
          );
          const rawContentState = convertToRaw(contentState);

          const currentField = jobData[fieldKey] || {};
          return { ...currentField, [targetLocale]: rawContentState };
        }
      } catch (error) {
        toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
      }
    }
    return jobData[fieldKey];
  };

  const handleSetJobData = async (
    jobId: JobID,
    jobData: JobProfile,
    handleJobUpdateSuccess: (jobId: string) => void,
    handleJobUpdateFail: () => void
  ) => {
    try {
      const {
        skills,
        company_id,
        job_title,
        job_description,
        must_requirements,
        job_overview,
        language_requirement
      } = jobData || {};

      if (isJobInformationStep || isAdditionalInformationStep) {
        const fieldsToTranslate: FieldKey[] = [
          "must_requirements",
          "job_description",
          "additional_information"
        ];

        for (const field of fieldsToTranslate) {
          if (
            dataLocaleShort === LOCALE_SHORT.EN &&
            jobData &&
            Object.keys(jobData).includes(field)
          ) {
            // Translate from EN to JA
            jobData[field] = await translateField(
              field,
              jobData,
              LOCALE_SHORT.EN,
              LOCALE_SHORT.JA
            );
          } else if (
            dataLocaleShort === LOCALE_SHORT.JA &&
            jobData &&
            Object.keys(jobData).includes(field)
          ) {
            // Translate from JA to EN
            jobData[field] = await translateField(
              field,
              jobData,
              LOCALE_SHORT.JA,
              LOCALE_SHORT.EN
            );
          }
        }
      }

      // handle job status
      if (
        isConfirmationStep &&
        skills?.length &&
        company_id &&
        job_title &&
        job_description &&
        must_requirements &&
        job_overview &&
        language_requirement?.length
      ) {
        // If this is the last step in the job entry process then set it to submission for auto-review. Otherwise, draft it because the employer may change the data at any time if it is an active job.
        jobData.status = JOB_POSTING_STATUS.SUBMITTED_FOR_AUTO_REVIEW;
        jobData.statusV2 = {
          en: JOB_POSTING_STATUS.SUBMITTED_FOR_AUTO_REVIEW,
          ja: JOB_POSTING_STATUS.SUBMITTED_FOR_AUTO_REVIEW
        };
        if (dataLocaleShort) {
          jobData.statusV2[dataLocaleShort as keyof MultiLingual] =
            JOB_POSTING_STATUS.SUBMITTED_FOR_AUTO_REVIEW;
        }
      } else {
        jobData.status = JOB_POSTING_STATUS.DRAFT;
        if (dataLocaleShort) {
          jobData.statusV2[dataLocaleShort as keyof MultiLingual] =
            JOB_POSTING_STATUS.DRAFT;
        }
      }

      if (companyDetails?.setJobValue) {
        if (jobId === "new") {
          let newJobId = "";
          // create new job and push job reference
          companyDetails?.setJobValue(
            jobId,
            jobData,
            async (updatedJobId) => {
              companyDetails?.value?.jobs?.push(updatedJobId);
              newJobId = updatedJobId;
              handleJobUpdateSuccess(newJobId);
              setJobData(jobData);
            },
            handleJobUpdateFail
          );
        } else {
          companyDetails?.setJobValue(
            jobId,
            jobData,
            () => {
              setJobData((prevJobData) => {
                return { ...prevJobData, ...jobData };
              });
              handleJobUpdateSuccess(jobId);
            },
            handleJobUpdateFail
          );
        }
      }
    } catch (e) {
      handleJobUpdateFail();
    }
  };

  return <Outlet context={{ jobData, handleSetJobData }} />;
};

export default EmployersPostJob;
